export const localStorageKeys: { [key: string]: string } = {
  walletConnection: "abridge:connected",
};

// Breakpoints taken from the components library
// https://github.com/Stichting-AllianceBlock-Foundation/components/blob/develop/src/styles/_variables.scss
export const breakpoints: { [breakpoint: string]: number } = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 991,
  xl: 1400,
};

export const TOKEN_SLIDER_PERCENTAGE_DECIMALS: number = 2;
export const TOKEN_FEE_DECIMALS: number = 2;
export const DEFAULT_FEE_CURRENCY: string = "usd";

export const NATIVE_SERVICE_FEE_TOKEN_SYMBOL: string = "WEWT";

export const SERVICE_FEE_TOKEN_WEJS_SYMBOL: string = "WEJS";

export const SERVICE_FEE_TOKEN_EJS_SYMBOL: string = "EJS";

export const ALBT_FAUCET_ADDRESS = "0xc6869a93ef55e1d8ec8fdcda89c9d93616cf0a72";

export const openSeaApiKey = process.env.REACT_APP_OPENSEA_ID;
