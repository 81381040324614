import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { Layout } from "components/Layout";
import { GlobalProvider } from "context/GlobalContext";

function App() {
  return (
    <Router>
      <GlobalProvider>
        <Layout />
      </GlobalProvider>
    </Router>
  );
}

export default App;
