import React from "react";
import { Trans, useTranslation } from "react-i18next";

function EmptyClaimsFooter() {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column justify-content-center align-items-center mt-5 mb-6">
      <p className="text-medium text-bold text-main text-center mb-3 mr-5">
        {t("claimPage:emptyClaim.title")}
      </p>
      <p className="text-main text-align-justify">
        <Trans
          i18nKey="claimPage:emptyClaim.description"
          components={{ span: <span /> }}
        />
      </p>
    </div>
  );
}

export default EmptyClaimsFooter;
