import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Select } from "components/Select";
import { useDefaultFilterOptionData } from "hooks/useDefaultFilterOption";
import { useGlobalContext } from "hooks/useGlobalContext";

import { NETWORK_CONFIG } from "../../../configs/networks";
import ClaimFilterDropdown from "./ClaimFilterDropdown";

function ClaimFilter() {
  const { currentNetwork, claims, setFilterCriteria, setIsClaimLoaded } =
    useGlobalContext();
  const { account } = useWeb3React();

  const { t } = useTranslation();
  const defaultFilterOption = useDefaultFilterOptionData();
  const [claimFilterIndex, setClaimFilterIndex] = useState(0);
  const [claimFilterNetworkIndex, setClaimFilterNetworkIndex] = useState(0);

  const allNetworksObject: any = {
    network: {
      chainName: t("claimPage:filterOptions.network"),
      chainTargetId: 0,
      symbol: t("claimPage:filterOptions.network"),
    },
    tokenAddresses: [],
  };

  const FILTER_SELECT_CONSTANTS = [
    {
      name: t("claimPage:filterOptions.constants.showAll"),
      icon: "check",
      index: 0,
    },
    {
      name: t("claimPage:filterOptions.constants.claimed"),
      icon: "check",
      index: 1,
    },
    {
      name: t("claimPage:filterOptions.constants.notClaimed"),
      icon: "check",
      index: 2,
    },
  ];

  useEffect(() => {
    setClaimFilterIndex(0);
    setClaimFilterNetworkIndex(0);
    setFilterCriteria(defaultFilterOption);

    if (!claims.length) {
      setIsClaimLoaded(true);
    }
  }, [currentNetwork, account]);

  return (
    <div className="claimed-option-wrapper mt-4 width-sm-100 width-md-auto">
      <div className="d-flex dropdown-option-wrapper">
        <Select
          className="mr-3 mb-4 mb-md-0 dropdown z-index-6"
          optionsList={Object.values({
            allNetworks: allNetworksObject,
            ...NETWORK_CONFIG,
          })?.map((item: any) => item?.network)}
          displayFields={{
            primary: "chainName",
            secondary: "",
            icon: "chainIcon",
          }}
          size="sm"
          selected={claimFilterNetworkIndex !== 0 ? claimFilterNetworkIndex : 0}
          onSelectOptionChange={(option) => {
            setFilterCriteria((prevState: any): void => {
              return {
                ...prevState,
                network: option.symbol,
              };
            });
            setClaimFilterNetworkIndex(option.chainTargetId);
          }}
        />
        <ClaimFilterDropdown
          className="dropdown"
          optionsList={Object.values(FILTER_SELECT_CONSTANTS)?.map(
            (item: FilterStatus) => item
          )}
          displayFields={{
            primary: "name",
            secondary: "",
            icon: "icon",
          }}
          size="sm"
          selected={claimFilterIndex}
          onSelectOptionChange={(option) => {
            setFilterCriteria((prevState: any): void => {
              return {
                ...prevState,
                status: option.name,
              };
            });
            setClaimFilterIndex(option.index);
          }}
        />
      </div>
    </div>
  );
}

export default ClaimFilter;
