import { Web3ReactProvider } from "@web3-react/core";
import React, { createContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { ThemeProvider } from "@stichting-allianceblock-foundation/components";
import { DEFAULT_FEE_CURRENCY } from "configs/constants";
import { NETWORK_CONFIG } from "configs/networks";
import { useWalletConnection } from "hooks/useWalletConnection";
import { getLibrary } from "utils/web3React";

const initialState: AppContext = {
  config: {
    brandColor: "",
    theme: "light",
    companyName: "AllianceBlock",
    companyLogoUrl: "/assets/img/allianceblock.svg",
    productName: "AllianceBridge",
    serviceFeeCurrency: DEFAULT_FEE_CURRENCY,
  },
  isWalletConnected: false as boolean,
  triedConnecting: false as boolean,
  isSideMenuOpen: false as boolean,
  currentNetwork: NETWORK_CONFIG.eth.network,
  networkOptions: [] as Network[],
  networkIndex: 0 as number,
  bridgeStatuses: {
    bridge: {
      activeClaims: 0 as number,
      inActiveClaims: 0 as number,
      historyNftTransactions: 0 as number,
    },
  },
  nftBridgeStatuses: {
    bridge: {
      activeNftClaims: 0 as number,
      inActiveNftClaims: 0 as number,
    },
  },
  bridgeTransaction: {
    network: {
      source: {} as Network,
      target: {} as Network,
    },
    recipient: "" as string,
    token: {
      details: {
        name: "" as string,
        symbol: "" as string,
        decimals: 0 as number,
        icon: "" as string,
      },
      amount: "0" as string,
      address: "" as string,
    },
    feeToken: {
      details: {
        name: "" as string,
        symbol: "" as string,
        decimals: 0 as number,
        icon: "" as string,
      },
      amount: "0" as string,
      address: "" as string,
    },
  },
  nftBridgeTransaction: {
    network: {
      source: {} as Network,
      target: {} as Network,
    },
    recipient: "" as string,
    nftTokens: [],
  },
  sdk: null,
  claims: [] as Claim[],
  nftClaims: [],
  serviceFeeOptions: [],
  filterCriteria: {
    network: "All Networks",
    status: "",
  } as FilterCriteria,
  claimModal: {
    status: false,
    claimData: {
      tokenAmount: "",
      tokenName: "",
      recipient: "",
      transactionLink: "",
      tokenType: "",
      tokenOptions: {
        address: "",
        symbol: "",
        decimals: "",
      },
    },
  } as ClaimModal,
  isClaimLoaded: false as boolean,
  isNftsLoaded: false as boolean,
  userCollections: [],
  addNftCollectionAddress: "",
  addNftTokenId: "",
  collectionCriteria: null,
  collectionFilterIndex: 0 as number,
  userAssets: [],
  nftClaimModal: {},
  networkSwitchScreen: {
    network: {} as Network,
    connecting: false,
  } as NetworkSwitchScreen,
  nftTransferPage: 1 as number,
  addedForTransfer: [],
};

export const GlobalContext = createContext<AppContext>(
  initialState as AppContext
);

export const UpdateGlobalContext = createContext<UpdateAppContext>({
  setConfig: (_value: Config) => {
    return;
  },
  setIsWalletConnected: (_value: boolean) => {
    return;
  },
  setTriedConnecting: (_value: boolean) => {
    return;
  },
  setIsSideMenuOpen: (_value: boolean) => {
    return;
  },
  setCurrentNetwork: (_value: Network) => {
    return;
  },
  setNetworkOptions: (_value: Network[]) => {
    return;
  },
  setNetworkIndex: (_value: number) => {
    return;
  },
  setBridgeStatuses: (_value: BridgeStatuses) => {
    return;
  },
  setNftBridgeStatuses: (_value: NftBridgeStatuses) => {
    return;
  },
  setBridgeTransaction: (_value: BridgeTransaction) => {
    return;
  },
  setNftBridgeTransaction: (_value: NFTBridgeTransaction) => {
    return;
  },
  setSDK: (_value: any) => {
    return;
  },
  setClaims: (_value: Claim[]) => {
    return;
  },
  setNftClaims: (_value: Claim[]) => {
    return;
  },
  setServiceFeeOptions: (_value: ServiceFeeToken[]) => {
    return;
  },
  setFilterCriteria: (_value: FilterCriteria) => {
    return;
  },
  setClaimModal: (_value: ClaimModal) => {
    return;
  },
  setIsClaimLoaded: (_value: boolean) => {
    return;
  },
  setIsNftsLoaded: (_value: boolean) => {
    return;
  },
  setUserCollections: (_value: any) => {
    return;
  },
  setAddNftCollectionAddress: (_value: string) => {
    return;
  },
  setAddNftTokenId: (_value: string) => {
    return;
  },
  setCollectionCriteria: (_value: any | null) => {
    return;
  },
  setCollectionFilterIndex: (_value: number) => {
    return;
  },
  setUserAssets: (_value: any) => {
    return;
  },
  setNftClaimModal: (_value: any) => {
    return;
  },
  setNetworkSwitchScreen: (_value: NetworkSwitchScreen) => {
    return;
  },
  setnftTransferPage: (_value: number) => {
    return;
  },
  setAddedForTransfer: (_value: any) => {
    return;
  },
});

export function GlobalProvider({
  children,
}: {
  children?: React.ReactChild | React.ReactChild[];
}) {
  const { t } = useTranslation();
  const [config, setConfig] = useState<Config>(initialState.config);
  const [isWalletConnected, setIsWalletConnected] = useWalletConnection(
    initialState.isWalletConnected
  );
  const [isSideMenuOpen, setIsSideMenuOpen] = useWalletConnection(
    initialState.isSideMenuOpen
  );
  const [triedConnecting, setTriedConnecting] = useWalletConnection(
    initialState.triedConnecting
  );
  const [currentNetwork, setCurrentNetwork] = useState<Network>(
    initialState.currentNetwork
  );
  const [networkOptions, setNetworkOptions] = useState<Network[]>(
    initialState.networkOptions
  );
  const [networkIndex, setNetworkIndex] = useState<number>(
    initialState.networkIndex
  );
  const [bridgeStatuses, setBridgeStatuses] = useState<BridgeStatuses>(
    initialState.bridgeStatuses
  );
  const [nftBridgeStatuses, setNftBridgeStatuses] = useState<NftBridgeStatuses>(
    initialState.nftBridgeStatuses
  );
  const [bridgeTransaction, setBridgeTransaction] = useState<BridgeTransaction>(
    initialState.bridgeTransaction
  );
  const [nftBridgeTransaction, setNftBridgeTransaction] =
    useState<NFTBridgeTransaction>(initialState.nftBridgeTransaction);
  const [sdk, setSDK] = useState(initialState.sdk);

  const [claims, setClaims] = useState(initialState.claims);
  const [nftClaims, setNftClaims] = useState(initialState.nftClaims);

  const [serviceFeeOptions, setServiceFeeOptions] = useState<ServiceFeeToken[]>(
    initialState.serviceFeeOptions
  );

  const [filterCriteria, setFilterCriteria] = useState<FilterCriteria>({
    network: t("claimPage:filterOptions.network"),
    status: t("claimPage:filterOptions.constants.showAll"),
  });
  const { brandColor, theme } = config;

  const [claimModal, setClaimModal] = useState(initialState.claimModal);

  const [isClaimLoaded, setIsClaimLoaded] = useState(
    initialState.isClaimLoaded
  );

  const [isNftsLoaded, setIsNftsLoaded] = useState(initialState.isNftsLoaded);

  const [userCollections, setUserCollections] = useState(
    initialState.userCollections
  );

  const [addNftCollectionAddress, setAddNftCollectionAddress] = useState(
    initialState.addNftCollectionAddress
  )

  const [addNftTokenId, setAddNftTokenId] = useState(
    initialState.addNftTokenId
  )

  const [collectionCriteria, setCollectionCriteria] = useState(
    initialState.collectionCriteria
  );
  const [collectionFilterIndex, setCollectionFilterIndex] = useState(
    initialState.collectionFilterIndex
  );

  const [userAssets, setUserAssets] = useState(initialState.userAssets);

  const [nftTransferPage, setnftTransferPage] = useState(
    initialState.nftTransferPage
  );

  const [nftClaimModal, setNftClaimModal] = useState(
    initialState.nftClaimModal
  );
  const [networkSwitchScreen, setNetworkSwitchScreen] = useState(
    initialState.networkSwitchScreen
  );
  const [addedForTransfer, setAddedForTransfer] = useState<any[]>(
    initialState.addedForTransfer
  );

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <ThemeProvider theme={theme} primaryColor={brandColor}>
        <GlobalContext.Provider
          value={{
            config,
            isWalletConnected,
            triedConnecting,
            isSideMenuOpen,
            currentNetwork,
            networkOptions,
            networkIndex,
            bridgeStatuses,
            nftBridgeStatuses,
            bridgeTransaction,
            nftBridgeTransaction,
            sdk,
            claims,
            nftClaims,
            serviceFeeOptions,
            filterCriteria,
            claimModal,
            isClaimLoaded,
            isNftsLoaded,
            userCollections,
            addNftCollectionAddress,
            addNftTokenId,
            collectionCriteria,
            collectionFilterIndex,
            userAssets,
            nftClaimModal,
            networkSwitchScreen,
            nftTransferPage,
            addedForTransfer,
          }}
        >
          <UpdateGlobalContext.Provider
            value={{
              setConfig,
              setIsWalletConnected,
              setTriedConnecting,
              setIsSideMenuOpen,
              setCurrentNetwork,
              setNetworkOptions,
              setNetworkIndex,
              setBridgeStatuses,
              setNftBridgeStatuses,
              setBridgeTransaction,
              setNftBridgeTransaction,
              setSDK,
              setClaims,
              setNftClaims,
              setServiceFeeOptions,
              setFilterCriteria,
              setClaimModal,
              setIsClaimLoaded,
              setIsNftsLoaded,
              setUserCollections,
              setAddNftCollectionAddress,
              setAddNftTokenId,
              setCollectionCriteria,
              setCollectionFilterIndex,
              setUserAssets,
              setNftClaimModal,
              setNetworkSwitchScreen,
              setnftTransferPage,
              setAddedForTransfer,
            }}
          >
            {children}
          </UpdateGlobalContext.Provider>
        </GlobalContext.Provider>
      </ThemeProvider>
    </Web3ReactProvider>
  );
}
