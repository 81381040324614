import { useTranslation } from "react-i18next";

export const useDefaultFilterOptionData = () => {
  const { t } = useTranslation();
  const defaultFilterOption: any = {
    network: t("claimPage:filterOptions.network"),
    status: t("claimPage:filterOptions.constants.showAll"),
  };

  return defaultFilterOption as any;
};
export default useDefaultFilterOptionData;
