import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Notification } from "@stichting-allianceblock-foundation/components";
import { useGlobalContext } from "hooks/useGlobalContext";

interface StepBridgeNotificationsProps {
  notEnoughBalanceForServiceFee: boolean;
  isBridgeTransactionTokenNativeToken: boolean;
  isTokenEWTB: boolean;
  notDeployedWrappedToken: boolean;
}

export const StepBridgeNotifications = ({
  notEnoughBalanceForServiceFee,
  isBridgeTransactionTokenNativeToken,
  isTokenEWTB,
  notDeployedWrappedToken,
}: StepBridgeNotificationsProps) => {
  const { bridgeTransaction } = useGlobalContext();
  const { t } = useTranslation();

  return (
    <div className="mt-4">
      {bridgeTransaction?.token?.address &&
        !["0", "0.0"].includes(bridgeTransaction?.token?.amount) &&
        notEnoughBalanceForServiceFee && (
          <Notification
            className="mb-4"
            type="danger"
            text={
              <p>
                <Trans
                  i18nKey="transferPage:notificationMessages.serviceFeeNotEnoughBalanceText"
                  components={{ span: <span /> }}
                  values={{
                    tokenSymbol: bridgeTransaction.feeToken.details.symbol,
                  }}
                />
              </p>
            }
            closable={false}
          />
        )}
      {isBridgeTransactionTokenNativeToken && (
        <Notification
          className="mb-4"
          type="warning"
          text={t(
            "transferPage:notificationMessages.isBridgeTokenNativeTokenText",
            {
              tokenName: bridgeTransaction?.token?.details?.name,
              tokenSymbol: bridgeTransaction?.token?.details?.symbol,
            }
          )}
          closable={false}
        />
      )}
      {isTokenEWTB && (
        <Notification
          className="mb-4"
          type="danger"
          text={
            <div className="d-flex flex-column">
              <p>
                <Trans
                  i18nKey="transferPage:notificationMessages.isEWTBText"
                  components={{ span: <span /> }}
                  values={{
                    tokenSymbol: bridgeTransaction.token.details.symbol,
                  }}
                />
              </p>
              <a
                className="link-primary"
                href="https://bridge.energyweb.org/"
                rel="noopener noreferrer"
              >
                POA Bridge UI
              </a>
            </div>
          }
          closable={false}
        />
      )}
      {notDeployedWrappedToken && !isTokenEWTB && (
        <Notification
          className="mb-4"
          type="warning"
          text={t(
            "transferPage:notificationMessages.notDeployedWrappedTokenText"
          )}
          closable={false}
        />
      )}
    </div>
  );
};

export default StepBridgeNotifications;
