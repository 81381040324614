import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FilterStatus } from "helpers/Dictionary";
import { useGlobalContext } from "hooks/useGlobalContext";

import { formatTransferList } from "../../../../../helpers/Claims";
import { ClaimListing } from "..";

function ClaimWindowContainer() {
  const { sdk, claims, currentNetwork, filterCriteria, networkOptions } =
    useGlobalContext();

  const { t } = useTranslation();

  const [formattedListedData, setFormattedListedData] = useState<
    FormattedTransfer[]
  >([]);

  const [filteredClaimList, setFilteredClaimList] = useState<
    FormattedTransfer[]
  >([]);

  const { status: statusFilter, network: networkFilter } = filterCriteria;

  const filterClaimListingByCriteria = (entry: FormattedTransfer) => {
    const status: { [value: string]: string } = {
      true: t("claimPage:filterOptions.constants.claimed"),
      false: t("claimPage:filterOptions.constants.notClaimed"),
    };
    return (
      (status[entry.status as string] === statusFilter ||
        statusFilter === t("claimPage:filterOptions.constants.showAll")) &&
      (entry.sourceNetwork.symbol === networkFilter ||
        networkFilter === t("claimPage:filterOptions.network"))
    );
  };

  useEffect(() => {
    const listFiltered =
      statusFilter === t("claimPage:filterOptions.constants.showAll") &&
      networkFilter === t("claimPage:filterOptions.network")
        ? formattedListedData
        : formattedListedData.filter(filterClaimListingByCriteria);

    setFilteredClaimList(listFiltered);
  }, [statusFilter, networkFilter, formattedListedData]);

  useEffect(() => {
    const updateTransfers = async () => {
      let xIsClaimed: number, yIsClaimed: number;
      const transfers = claims
        ? await formatTransferList(
            sdk,
            currentNetwork.blockExplorerUrl,
            claims,
            networkOptions
          ).sort((x: FormattedTransfer, y: FormattedTransfer) => {
            xIsClaimed = x.status === FilterStatus.claimed ? 1 : 0;
            yIsClaimed = y.status === FilterStatus.claimed ? 1 : 0;

            return xIsClaimed - yIsClaimed;
          })
        : [];
      setFormattedListedData(transfers);
    };

    updateTransfers();
  }, [sdk, claims]);
  return filteredClaimList ? (
    <ClaimListing claimData={filteredClaimList}></ClaimListing>
  ) : null;
}

export default ClaimWindowContainer;
