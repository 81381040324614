import React, { useEffect, useRef, useState } from "react";

import { Icon } from "@stichting-allianceblock-foundation/components";

import "./Accordion.scss";

const Accordion = ({ title, children, icon, expanded }: AccordionProps) => {
  const [open, setOpen] = useState<boolean>(expanded ?? false);

  const contentRef = useRef(null);
  const containerRef = useRef(null);

  function handleClickOutside(event: Event) {
    let itemContainsLayout: boolean = false;
    let itemContainsContainerAccordion: boolean = false;
    let itemContainsAccordion: boolean = false;

    event.composedPath().forEach((node) => {
      if ((node as any).classList !== undefined) {
        if ((node as any).classList.contains("layout-content")) {
          itemContainsLayout = true;
        }

        if (
          (node as any).classList.contains("sidemenu-content") ||
          (node as any).classList.contains("sidebar-container")
        ) {
          itemContainsContainerAccordion = true;
        }

        if ((node as any).classList.contains("accordion-section")) {
          itemContainsAccordion = true;
        }
      }
    });

    if (
      !(containerRef.current as any).contains(event.target) &&
      !itemContainsLayout! &&
      itemContainsContainerAccordion! &&
      itemContainsAccordion!
    ) {
      setOpen(false);
    }
  }

  useEffect(() => {
    (contentRef.current as any).style.maxHeight = open
      ? `${(contentRef.current as any).scrollHeight}px`
      : "0px";

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [contentRef, open]);

  return (
    <div ref={containerRef} className="accordion-section">
      <a
        className="accordion-title justify-content-between"
        onClick={() => setOpen(!open)}
      >
        {icon && (
          <Icon name={icon} size={24} color="ui-main" className="mr-3" />
        )}
        <span className="accordion-title__item">{title}</span>
        <Icon
          className={`${open ? "arrow" : "arrow rotated"} mx-3`}
          name="chevron-down"
          size={16}
          color="ui-secondary"
        />
      </a>
      <ul ref={contentRef} className="accordion-content">
        {children}
      </ul>
    </div>
  );
};

export default React.memo(Accordion);
