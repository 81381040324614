import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button, Icon } from "@stichting-allianceblock-foundation/components";
import { Checkbox } from "components/Checkbox";
import { useModalContext } from "hooks/useModalContext";

import "./TransferConfirmation.scss";

interface TransferConfirmationProps {
  title: string;
  subtitle: string;
  confirmationTerms: string;
  onConfirmation: () => void;
}

const TransferConfirmation = ({
  title,
  subtitle,
  confirmationTerms,
  onConfirmation,
}: TransferConfirmationProps) => {
  const { setOpen } = useModalContext();
  const { t } = useTranslation();
  const [checked, setChecked] = useState<boolean>(false);

  const handleConfirmation = () => {
    onConfirmation();
    setOpen(false);
  };

  return (
    <div className="transfer-confirmation-modal">
      <div className="transfer-confirmation-modal-screen d-flex flex-column">
        <Icon
          className="align-self-end mr-5"
          name="nav-cross"
          size={20}
          color="ui-main-background"
          onClick={() => {
            setOpen(false);
          }}
        />
        <Icon
          className="align-self-center"
          size={32}
          name="attention"
          color="ui-main-background"
        />
      </div>
      <div className="transfer-confirmation-modal-content">
        <h1 className="text-center mt-3 mb-4">{title}</h1>
        <Trans i18nKey={subtitle} components={{ p: <p /> }} />
        <Checkbox
          className="mt-4 mb-3"
          label={confirmationTerms}
          checked={checked}
          onCheck={() => setChecked(!checked)}
        />
        <div className="d-flex flex-column flex-md-row justify-content-md-between mt-5">
          <Button type="alarm" onClick={() => setOpen(false)}>
            <Icon name="cross" size={18} color="ui-border" />
            <span className="mr-2">{t("transferPage:cancelButton")}</span>
          </Button>
          <Button
            className="mt-3 mt-md-0"
            type="primary"
            onClick={handleConfirmation}
            disabled={!checked}
          >
            <span className="mr-2">{t("transferPage:transferButton")}</span>
            <Icon name="arrow-right-long" size={18} color="ui-border" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TransferConfirmation;
