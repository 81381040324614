import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Icon } from "@stichting-allianceblock-foundation/components";
import { useGlobalContext } from "hooks/useGlobalContext";
import { useModalContext } from "hooks/useModalContext";
import { getProtocolsByNetwork } from "utils";

import "./BuyALBT.scss";

interface BuyALBTProps {
  onClose: () => void;
}

const BuyALBT = ({ onClose }: BuyALBTProps) => {
  const { currentNetwork, sdk } = useGlobalContext();
  const { setOpen } = useModalContext();
  const { t } = useTranslation();
  const [albtAddress, setAlbtAddress] = useState<string>("");

  useEffect(() => {
    const loadAlbtAddress = async () => {
      if (sdk) {
        try {
          const albtToken = await sdk.getALBTTokenLegacy();
          setAlbtAddress(albtToken.address);
        } catch (err: any) {
          console.error(err);
          setAlbtAddress("");
        }
      }
    };

    loadAlbtAddress();
  });

  useEffect(() => {
    return () => onClose();
  }, []);

  return (
    <div className="buy-albt-modal">
      <div className="buy-albt-modal-screen d-flex flex-column">
        <Icon
          className="align-self-end mr-5"
          name="nav-cross"
          size={20}
          color="ui-main"
          onClick={() => {
            setOpen(false);
            onClose();
          }}
        />
      </div>

      <div className="buy-albt-modal-content">
        <div className="d-flex align-items-center">
          <div className="text-medium text-bold text-main mb-3 mr-3">
            {t("buyAlbt:title")}
          </div>
        </div>
        <div className="text-main">{t("buyAlbt:subtitle")}</div>
        <div className="buy-albt-modal-protocols mt-4">
          <div className="d-flex align-items-center">
            <div className="text-small text-bold text-secondary mb-3 mr-3">
              {t("buyAlbt:protocolListSection.title")}
            </div>
          </div>
          {getProtocolsByNetwork(currentNetwork.chainId, albtAddress)?.map(
            (protocol: DEXProtocol, index: number) => (
              <div className="d-flex justify-content-between my-5" key={index}>
                <div className="d-flex align-items-center">
                  <Icon name={protocol.icon} size={32} />
                  <span className="text-main text-bold ml-2">
                    {protocol.name}
                  </span>
                </div>
                <Button
                  className="width-auto"
                  type="primary"
                  onClick={() => open(protocol.url)}
                >
                  {t("buyAlbt:openBtn")}
                </Button>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default BuyALBT;
