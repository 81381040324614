import { JsonRpcSigner } from "@ethersproject/providers";
import albtAbi from "configs/abis/ALBT.json";
import { ALBT_FAUCET_ADDRESS } from "configs/constants";
import { ethers } from "ethers";

export const getContract = (
  address: string,
  abi: any,
  signer: ethers.Signer | JsonRpcSigner
): ethers.Contract => {
  return new ethers.Contract(address, abi, signer);
};

export const getAlbtContract = (signer: ethers.Signer) => {
  return getContract(ALBT_FAUCET_ADDRESS, albtAbi, signer);
};
