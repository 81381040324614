import { TOKEN_SLIDER_PERCENTAGE_DECIMALS } from "configs/constants";
import { ethers } from "ethers";

export const toHex = (number: number): string => {
  return ethers.utils.hexStripZeros(ethers.utils.hexlify(number));
};

export const isAddress = (address: string): boolean => {
  return ethers.utils.isAddress(address);
};

export const toWei = (value: string, decimals?: number): ethers.BigNumber =>
  ethers.utils.parseUnits(value, decimals);

export const fromWei = (
  value: ethers.BigNumberish,
  decimals?: number
): string => ethers.utils.formatUnits(value, decimals);

export const toBN = (value: string): ethers.BigNumber =>
  ethers.BigNumber.from(value);

export const calculateTokenAmountByPercentage = (
  percentage: string,
  totalAmount: string,
  decimals: number
): string => {
  const amount = toWei(percentage, decimals)
    .mul(toWei(totalAmount, decimals))
    .div(toWei("1", decimals + TOKEN_SLIDER_PERCENTAGE_DECIMALS));

  const amountResult = fromWei(amount.toString(), decimals);

  return amountResult;
};

export const calculateTokenPercentageByAmount = (
  amount: string,
  totalAmount: string,
  decimals: number
): number => {
  if (Number(totalAmount) <= 0) {
    return 0;
  }

  let amountBN;
  try {
    amountBN = toWei(amount, decimals);
  } catch (err) {
    amountBN = toBN("0");
  }

  let percentage: ethers.BigNumber;
  if (amountBN.gte(0) && amountBN.lte(toWei(totalAmount, decimals))) {
    percentage = amountBN
      .mul(toWei("100", decimals))
      .div(toWei(totalAmount, decimals));
  } else {
    percentage = toBN("0");
  }

  return Number(
    Number(fromWei(percentage, decimals)).toFixed(
      TOKEN_SLIDER_PERCENTAGE_DECIMALS
    )
  );
};

export const getTokenIcon = (
  tokenDetails: TokenDetails,
  prefix: string = "coin-"
): string => {
  if (!tokenDetails?.name?.includes("Wrapped")) {
    return `${prefix}${tokenDetails?.symbol?.toLowerCase()}`;
  }

  const symbol = tokenDetails?.symbol?.toLowerCase()?.replace(/^w/, "");
  return `${prefix}${symbol}`;
};

export const svgUrlExists = (url: string): boolean => {
  const http = new XMLHttpRequest();
  http.open("GET", url, false);
  http.send();
  return http.responseText?.startsWith("<svg");
};

export const formatCurrency = (
  value: number,
  decimals: number,
  symbol: string
): string => {
  if (value < 1 / 10 ** decimals) {
    return "(<$ 0.01)";
  }

  return `(${symbol} ${value.toFixed(decimals)})`;
};

export const formatTokenInput = (value: string): string => {
  if (value.match(/^(0[1-9]+)/) || value.match(/^0{2,}[1-9]+/)) {
    // to remove any leading zero after a number
    return value.replace(/^[0.]+/, "");
  } else if (value.match(/^0{2,}[\.,]/)) {
    // to remove leading zeroes when has decimal point
    return value.replace(/^0+/, "0");
  } else if (value.match(/^0{2}/)) {
    // to remove zeroes in the beginning
    return value.replace(/^[0.]+/, "0");
  }
  // if any check is met, returns the value as is
  return value;
};

export const shortenHash = (hash: string, offset: number): string => {
  const len: number = hash?.length;
  return `${hash?.substring(0, offset)} ... ${hash?.substring(
    len - offset,
    len
  )}`;
};

export const copyToClipboard = (content: string) => {
  navigator.clipboard.writeText(content);
};

export enum TxStatus {
  READY = 1,
  NOT_READY = 0,
}

export const getBlockExplorerNameByChainId = (
  chainId: number | undefined
): string => {
  switch (chainId) {
    case 1:
    case 4:
      return "Etherscan";
    case 56:
    case 97:
      return "BSCscan";
    case 137:
    case 80001:
      return "Polygonscan";
    case 43113:
    case 43114:
      return "Snowtrace";
    case 246:
    case 73799:
      return "EnergyWeb Explorer";
    case 42161:
    case 421611:
      return "Arbiscan";
    case 69:
      return "Optimistic Etherscan";
    case 1287:
      return "Moonscan";
    case 1313161555:
      return "Aurora Explorer";
    case 4002:
      return "FTMScan";
    default:
      return "Etherscan";
  }
};

export enum NftTokenStatus {
  Checking,
  Approve,
  Transfer,
}

export const open = (url: string) => {
  const win = window.open(url, "_blank");
  if (win != null) {
    win.focus();
  }
};

export const getProtocolsByNetwork = (
  chainId: number,
  asset: string
): DEXProtocol[] | null => {
  switch (chainId) {
    case 1:
      return [
        {
          name: "Uniswap",
          icon: "uniswap",
          url: `https://app.uniswap.org/#/swap?chain=mainnet&outputCurrency=${asset}`,
        },
        {
          name: "Balancer",
          icon: "balancer",
          url: "https://app.balancer.fi/#/trade",
        },
      ];
    case 56:
      return [
        {
          name: "PancakeSwap",
          icon: "pancakeswap",
          url: `https://pancakeswap.finance/swap?outputCurrency=${asset}`,
        },
      ];
    case 43114:
      return [
        {
          name: "Pangolin",
          icon: "pangolin",
          url: `https://app.pangolin.exchange/#/swap?outputCurrency=${asset}`,
        },
      ];
    default:
      return null;
  }
};

export const isProtocolAvailable = (chainId: number): boolean => {
  return getProtocolsByNetwork(chainId, "") !== null;
};
