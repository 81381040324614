import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Route, Switch } from "react-router";

import { RouterSDK } from "@allianceblock/abridge-sdk";
import { JsonRpcSigner } from "@ethersproject/providers";
import { Button, Icon } from "@stichting-allianceblock-foundation/components";
import { ReactComponent as ConnectWalletDesktopLogo } from "assets/connect-wallet-desktop.svg";
import { ReactComponent as ConnectWalletMobileLogo } from "assets/connect-wallet-mobile.svg";
import { Header } from "components/Header";
import { SideMenu } from "components/SideMenu";
import {
  NATIVE_SERVICE_FEE_TOKEN_SYMBOL,
  SERVICE_FEE_TOKEN_EJS_SYMBOL,
  SERVICE_FEE_TOKEN_WEJS_SYMBOL,
  TOKEN_FEE_DECIMALS,
} from "configs/constants";
import { NETWORK_CONFIG } from "configs/networks";
import { useBreakpoint } from "hooks/useBreakpoint";
import { useEagerConnect } from "hooks/useEagerConnect";
import { useGlobalContext } from "hooks/useGlobalContext";
import { useInactiveListener } from "hooks/useInactiveListener";
import { BridgeTransfer } from "pages/Bridge";
import { ClaimPage } from "pages/Bridge/ClaimPage";
import { formatCurrency, fromWei, getTokenIcon } from "utils";
import {
  currencySymbols,
  getCoingeckooIds,
  getTokenPrice,
} from "utils/coingecko";
import {
  addNetwork,
  changeCurrentNetwork,
  getMetamaskNetworkParams,
  getMetamaskProvider,
  MetamaskErrorCodes,
} from "utils/metamask";
import { getNetworkIndex } from "utils/network";
import { ConnectorNames, connectorsByName } from "utils/web3React";

import { getClaimCounters, getFormattedSdkClaims } from "../../helpers/Claims";

import "./Layout.scss";

const Layout = () => {
  const {
    config,
    isWalletConnected,
    currentNetwork,
    networkOptions,
    triedConnecting,
    bridgeTransaction,
    sdk,
    claims,
    bridgeStatuses,
    networkSwitchScreen,
    setTriedConnecting,
    setIsWalletConnected,
    setNetworkIndex,
    setNetworkOptions,
    setIsSideMenuOpen,
    setBridgeTransaction,
    // setNftBridgeTransaction,
    setSDK,
    setServiceFeeOptions,
    setIsClaimLoaded,
    setIsNftsLoaded,
    setBridgeStatuses,
    setClaims,
    setNetworkSwitchScreen,
  } = useGlobalContext();
  const {
    connector,
    library,
    chainId,
    deactivate,
    activate,
    active,
    account,
    error,
  } = useWeb3React();
  const [activatingConnector, setActivatingConnector] = useState();
  const { width, greaterThan } = useBreakpoint();
  const { t } = useTranslation();
  const [signer, setSigner] = useState<JsonRpcSigner>();

  const maxAttempts: number = 10;

  useEffect(() => {
    setIsClaimLoaded(true);
    setIsNftsLoaded(true);
  }, [account]);

  useEffect(() => {
    if (!claims?.length) {
      setIsClaimLoaded(true);
    }
    const updateClaims = async () => {
      try {
        const formattedClaims: Claim[] = await getFormattedSdkClaims(
          sdk,
          account as string,
          currentNetwork.chainTargetId as number
        );
        setClaims(formattedClaims);
        getToBeClaimedCounter(formattedClaims);
      } catch (error: any) {
        console.error(error);
      }
      setIsClaimLoaded(false);
    };

    if (sdk) {
      updateClaims();
    }
  }, [sdk, account]);

  function getToBeClaimedCounter(claims: Claim[]) {
    const { counterClaimed, counterNotClaimed } = getClaimCounters(claims);
    setBridgeStatuses({
      ...bridgeStatuses,
      bridge: {
        ...bridgeStatuses.bridge,
        activeClaims: counterClaimed,
        inActiveClaims: counterNotClaimed,
      },
    });
  }

  // function getNftCounters(claimsNft: any) {
  //   const { counterClaimed, counterNotClaimed } =
  //     getNftClaimCounters(claimsNft);
  //   setNftBridgeStatuses({
  //     ...nftBridgeStatuses,
  //     bridge: {
  //       ...nftBridgeStatuses.bridge,
  //       activeNftClaims: counterClaimed,
  //       inActiveNftClaims: counterNotClaimed,
  //     },
  //   });
  // }

  // useEffect(() => {
  //   setAddNftCollectionAddress("");
  //   setAddNftTokenId("");

  //   if (!nftClaims?.length) {
  //     setIsNftsLoaded(true);
  //   }
  //   const loadNftClaims = async () => {
  //     try {
  //       if (sdk) {
  //         const formattedNftClaims = await getFormattedSdkNftClaims(
  //           sdk,
  //           account as string,
  //           currentNetwork.chainTargetId as number,
  //           networkOptions as Network[]
  //         ).catch((error: any) => {
  //           console.log("ERROR CATCHED FROM FORMAT NFT", error);
  //           setNftClaims([]);
  //           setIsNftsLoaded(false);
  //         });
  //         setNftClaims(Object.values(formattedNftClaims as object));
  //         getNftCounters(Object.values(formattedNftClaims as object));
  //         setIsNftsLoaded(false);
  //       }
  //     } catch (error) {
  //       setIsNftsLoaded(false);
  //       setNftClaims([]);
  //       console.log("LOAD NFT CLAIMS ERROR", error);
  //     }
  //   };

  //   loadNftClaims();
  // }, [chainId, currentNetwork, sdk, account]);

  useEffect(() => {
    const loadSigner = async () => {
      if (library) {
        try {
          setSigner(library.getSigner());
        } catch (error) {
          console.log("error catched");
        }
      }
    };

    loadSigner();
  }, [library]);

  useEffect(() => {
    const loadSDK = async () => {
      // We need to wait until the provider network is loaded in the signer to load the SDK
      // Because we use it in the SDK to get the signer's chainId
      if (signer?.provider?.network) {
        const _sdk = await new RouterSDK(signer, {
          validatorTargetVersion: process.env.REACT_APP_VERSION,
          nftValidatorTargetVersion: process.env.REACT_APP_VERSION,
        });
        setSDK(_sdk);
      }
    };

    loadSDK();
  }, [signer?.provider?.network]);

  useEffect(() => {
    // So, we make sure that the side menu always is close when meets lg breakpoint
    if (greaterThan("lg")) {
      setIsSideMenuOpen(false);
    }
  }, [width]);

  useEffect(() => {
    const loadNetworkOptions = () => {
      const networkOptions_ = Object.values(NETWORK_CONFIG)?.map(
        (item: any) => item?.network
      );
      setNetworkOptions(networkOptions_);
    };

    loadNetworkOptions();
  }, []);

  useEffect(() => {
    let attemptCount: number = 0;
    let wejsFeeToken: ServiceFeeToken;
    const loadServiceFeeOptions = async () => {
      if (sdk) {
        try {
          // Get ALBT service fee token
          const albtFeeToken = await sdk.getALBTTokenLegacy();

          const albtCoingeckoId = getCoingeckooIds(
            albtFeeToken?.symbol?.toLowerCase()
          ).id;

          const albtPrice = await getTokenPrice(
            albtCoingeckoId,
            config.serviceFeeCurrency
          );

          const serviceFeeBN = await sdk.getServiceFeeLegacy();
          const externalFeeBN = await sdk.getExternalFeeLegacy();
          const serviceFee = fromWei(
            serviceFeeBN.add(externalFeeBN).toString(),
            albtFeeToken.decimals
          );

          const albtServiceFeeToken: ServiceFeeToken = {
            amount: serviceFee,
            amountInCurrency: formatCurrency(
              Number(serviceFee) * albtPrice,
              TOKEN_FEE_DECIMALS,
              currencySymbols[config.serviceFeeCurrency]
            ),
            icon: "albt",
            ...albtFeeToken,
          };

          // Get native service fee token
          const tokenAddresses = Object.values(NETWORK_CONFIG)?.filter(
            (config: any) =>
              config?.network?.chainId === currentNetwork?.chainId
          )[0]?.tokenAddresses;

          const address = tokenAddresses[NATIVE_SERVICE_FEE_TOKEN_SYMBOL];

          const addressEJS = tokenAddresses[SERVICE_FEE_TOKEN_EJS_SYMBOL]
            ?.length
            ? tokenAddresses[SERVICE_FEE_TOKEN_EJS_SYMBOL]
            : tokenAddresses[SERVICE_FEE_TOKEN_WEJS_SYMBOL];

          if (!address) {
            console.error("Service fee token address is empty");
            const feeTokenList: ServiceFeeToken[] = [albtServiceFeeToken];
            setServiceFeeOptions(feeTokenList);
          }

          const details = (
            await sdk.getTokenDetailsMulticall([address], account)
          )[0];
          let detailsWejs;

          if (addressEJS?.length) {
            try {
              detailsWejs = (
                await sdk.getTokenDetailsMulticall([addressEJS], account)
              )[0];

              if (Object.keys(detailsWejs).length !== 0) {
                const coingeckoIdWejs = getCoingeckooIds(
                  detailsWejs?.symbol?.toLowerCase()
                ).id;

                const priceWejs = await getTokenPrice(
                  coingeckoIdWejs,
                  config.serviceFeeCurrency
                );

                const amountBNWejs = await sdk.getProxyFeeLegacy(addressEJS);

                const amountWejs = fromWei(amountBNWejs, details.decimals);

                wejsFeeToken = {
                  amount: amountWejs,
                  amountInCurrency: formatCurrency(
                    Number(amountWejs) * priceWejs,
                    TOKEN_FEE_DECIMALS,
                    currencySymbols[config.serviceFeeCurrency]
                  ),
                  icon: "coin-ejs",
                  address: addressEJS,
                  ...detailsWejs,
                };
              }
            } catch (error) {
              console.error("Cannot get WEJS token fee");
            }
          }

          const icon = getTokenIcon(details);
          const coingeckoId = getCoingeckooIds(
            details?.symbol?.toLowerCase()
          ).id;

          const price = await getTokenPrice(
            coingeckoId,
            config.serviceFeeCurrency
          );
          const amountBN = await sdk.getProxyFeeLegacy(address);
          const amount = fromWei(amountBN, details.decimals);

          const nativeServiceFeeToken: ServiceFeeToken = {
            amount: amount,
            amountInCurrency: formatCurrency(
              Number(amount) * price,
              TOKEN_FEE_DECIMALS,
              currencySymbols[config.serviceFeeCurrency]
            ),
            icon: icon,
            address: address,
            ...details,
          };

          const feeTokenList: ServiceFeeToken[] = wejsFeeToken
            ? [albtServiceFeeToken, nativeServiceFeeToken, wejsFeeToken]
            : [albtServiceFeeToken, nativeServiceFeeToken];

          setServiceFeeOptions(feeTokenList);
        } catch (err) {
          console.error(err);
          setServiceFeeOptions([]);
        }
      }
    };
    try {
      loadServiceFeeOptions();
    } catch (error) {
      attemptCount++;
      console.error(error);
      if (attemptCount < maxAttempts) {
        loadServiceFeeOptions();
      }
    }
    return () => {
      setServiceFeeOptions([]);
    };
  }, [sdk, currentNetwork, account]);

  useEffect(() => {
    const loadBridgeTransactionRecipientIfActive = () => {
      if (active && account) {
        setBridgeTransaction({
          ...bridgeTransaction,
          recipient: account,
        });
      }
    };

    loadBridgeTransactionRecipientIfActive();
  }, [active, account]);

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const triedEager = useEagerConnect();

  useInactiveListener(!triedEager || !!activatingConnector);

  function handleDisconnect() {
    setIsWalletConnected(false);
    deactivate();
  }

  async function handleConnectInjected() {
    await activate(connectorsByName[ConnectorNames.Injected]);
    setNetworkIndex(getNetworkIndex(currentNetwork?.chainId, networkOptions));
    setIsWalletConnected(true);
    setTriedConnecting(true);
    const { ethereum } = window as any;
    ethereum?.removeAllListeners(["networkChanged"]);
  }

  useEffect(() => {
    const changeNetworkIfNotTheSame = async () => {
      if (
        !!library &&
        chainId !== currentNetwork.chainId &&
        triedConnecting &&
        isWalletConnected
      ) {
        try {
          setNetworkSwitchScreen({
            ...networkSwitchScreen,
            network: currentNetwork,
            connecting: true,
          });
          await changeCurrentNetwork(currentNetwork.chainId, library.provider);
          setIsWalletConnected(true);
        } catch (err: any) {
          if (err.code === MetamaskErrorCodes.NotNetworkFound) {
            try {
              setNetworkSwitchScreen({
                ...networkSwitchScreen,
                network: currentNetwork,
                connecting: true,
              });
              await addNetwork(
                getMetamaskNetworkParams(currentNetwork),
                library.provider
              );
            } catch (err) {
              console.error(
                "MetaMask - Add Network: An error ocurred when trying to add a new network.",
                err
              );
              setNetworkIndex(
                getNetworkIndex(currentNetwork.chainId, networkOptions)
              );
              setNetworkSwitchScreen({
                ...networkSwitchScreen,
                connecting: false,
              });
              handleDisconnect();
            }
          } else if (err.code === MetamaskErrorCodes.UserRejectedRequest) {
            setNetworkSwitchScreen({
              ...networkSwitchScreen,
              connecting: false,
            });
            handleDisconnect();
          }
        }
        setTriedConnecting(false);
      }
    };

    changeNetworkIfNotTheSame();
  }, [library, currentNetwork?.chainId, chainId, triedConnecting]);

  useEffect(() => {
    const changeNetworkIfNotSupported = async () => {
      if (error instanceof UnsupportedChainIdError && triedConnecting) {
        const provider = getMetamaskProvider();
        try {
          setNetworkSwitchScreen({
            ...networkSwitchScreen,
            network: currentNetwork,
            connecting: true,
          });
          await changeCurrentNetwork(currentNetwork?.chainId, provider);
        } catch (err: any) {
          if (err.code === MetamaskErrorCodes.NotNetworkFound) {
            try {
              setNetworkSwitchScreen({
                ...networkSwitchScreen,
                network: currentNetwork,
                connecting: true,
              });
              await addNetwork(
                getMetamaskNetworkParams(currentNetwork),
                provider
              );
            } catch (err) {
              console.error(
                "MetaMask - Add Network: An error ocurred when trying to add a new network.",
                err
              );
              setNetworkSwitchScreen({
                ...networkSwitchScreen,
                connecting: false,
              });
            }
          } else {
            console.error(err);
            setNetworkSwitchScreen({
              ...networkSwitchScreen,
              connecting: false,
            });
          }
        }
      }
    };

    changeNetworkIfNotSupported();
  }, [error, triedConnecting]);

  const renderNetworkSwitchScreen = () => {
    return (
      <div className="network-switch-screen d-flex flex-column justify-content-center align-items-center pulse">
        <div className="text-main text-medium">
          {t("layout:networkSwitchScreen.title")}
        </div>
        <Icon
          size={32}
          name={networkSwitchScreen.network.chainIcon}
          className="mt-4"
        />
        <div className="text-main text-bold mt-3">
          {networkSwitchScreen.network.chainName}
        </div>

        <div className="text-main text-center">
          <div className="line my-5 mx-8"></div>
          <Trans
            i18nKey="layout:networkSwitchScreen.description"
            components={{ span: <span /> }}
            values={{ chainName: networkSwitchScreen.network.chainName }}
          />
        </div>
      </div>
    );
  };

  const renderNotConnected = () => {
    return (
      <div className="mt-8 mb-5 my-md-7 fade-in-400">
        <div className="text-medium text-bold text-main mb-3 mr-5">
          {t("layout:notConnected.title")}
        </div>

        <div className="text-main">
          <Trans
            i18nKey="layout:notConnected.subtitle"
            components={{ span: <span /> }}
          />
        </div>

        <div className="d-flex justify-content-center mt-4">
          {greaterThan("md") ? (
            <ConnectWalletDesktopLogo className="max-width-100" />
          ) : (
            <ConnectWalletMobileLogo className="max-width-100" />
          )}
        </div>

        <div className="mt-4 mb-5">
          <div className="text-medium text-bold text-main text-center mb-3">
            {t("layout:notConnected.welcome", {
              productName: config.productName,
            })}
          </div>
          <div className="text-center">
            <Trans
              i18nKey="layout:notConnected.welcomeDescription"
              components={{ span: <span /> }}
              values={{ productName: config.productName }}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Button type="primary" onClick={() => handleConnectInjected()}>
            <Icon
              color="uiElementSecondary"
              size={20}
              name="wallet"
              className="mr-3"
            />
            <span>{t("walletButton:button.connectWallet")}</span>
          </Button>
        </div>
      </div>
    );
  };

  const renderConnected = () => {
    return (
      <div className="fade-in-400">
        <Switch>
          <Route exact path="/" component={BridgeTransfer} />
          <Route exact path="/bridge/claim" component={ClaimPage} />
        </Switch>
      </div>
    );
  };

  return (
    <>
      <SideMenu
        logo={config.companyLogoUrl}
        title={config.companyName}
        subtitle={t("sideMenu:subtitle")}
      />

      <div className="layout-content">
        <Header />
        <div className="content-wrapper">
          <div className="content">
            {!networkSwitchScreen.connecting
              ? isWalletConnected
                ? renderConnected()
                : renderNotConnected()
              : renderNetworkSwitchScreen()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
