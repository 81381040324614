import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Icon,
  TextField,
} from "@stichting-allianceblock-foundation/components";
import { useDetectOutsideClick } from "hooks/useDetectClickOutside";
import { useGlobalContext } from "hooks/useGlobalContext";
import { getTokenIcon, isAddress, svgUrlExists } from "utils";

import "./TokenSelect.scss";

const TokenSelect = ({
  className,
  tokenList,
  tokenSelected,
  onChange,
  onTokenSelectedClick,
  onClear,
  loading,
  error,
  tokenIsNotWrapped,
}: TokenSelectProps) => {
  const dropdownRef = useRef(null);
  const { sdk } = useGlobalContext();
  const { account } = useWeb3React();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useDetectOutsideClick(dropdownRef, false);
  const [internalTokenList, setInternalTokenList] =
    useState<TokenDetails[]>(tokenList);
  const [tokenSelectionText, setTokenSelectionText] = useState<string>("");
  const [hasError, setHasError] = useState<boolean>(false);
  const [loadingToken, setLoadingToken] = useState<boolean>(false);

  useEffect(() => {
    setInternalTokenList(tokenList);
  }, [tokenList]);

  const handleDropdown = () => {
    setExpanded(!expanded);
  };

  const handleSelection = (option: TokenDetails) => {
    if (onChange) {
      onChange(option);
    }
    setExpanded(false);
  };

  const handleTokenInputChange = async (value: string) => {
    setTokenSelectionText(value);
    if (!isAddress(value)) {
      const newTokenList = tokenList?.filter((token: TokenDetails) => {
        const { name, symbol } = token;
        const valueRegex = new RegExp(`^${value.toLowerCase()}`);
        return (
          valueRegex.test(name.toLowerCase()) ||
          valueRegex.test(symbol.toLowerCase())
        );
      });
      setInternalTokenList(newTokenList);
    } else {
      try {
        setLoadingToken(true);
        const details = (
          await sdk.getTokenDetailsMulticall([value], account)
        )[0];
        const tokenIcon = getTokenIcon(details);
        setInternalTokenList([
          {
            icon: svgUrlExists(`/icons/${tokenIcon}.svg`)
              ? tokenIcon
              : "assets",
            name: details?.name,
            symbol: details?.symbol,
            decimals: details?.decimals,
            address: value,
          },
        ]);
      } catch (err) {
        setInternalTokenList([]);
        console.error(err);
      } finally {
        setLoadingToken(false);
      }
    }
  };

  const handleClearTokenSelected = () => {
    if (onClear) {
      onClear();
    }
    setTokenSelectionText("");
    setInternalTokenList(tokenList);
  };

  const renderTokenList = () => {
    return (
      <ul
        className={`token-select-dropdown-list token-select-dropdown-list-${
          expanded ? "show" : "fade"
        } px-3 pb-4`}
      >
        <li className="mb-3"></li>
        {loading ? (
          <li className="pulse">
            <Icon name="spinner" size={18} className="mr-3" />
            <span>{t("tokenSelect:loadingMessage")}</span>
          </li>
        ) : loadingToken ? (
          <li className="pulse">
            <Icon name="spinner" size={18} className="mr-3" />
            <span>{t("tokenSelect:loadingTokenMessage")}</span>
          </li>
        ) : internalTokenList?.length > 0 ? (
          internalTokenList?.map((details, index) => {
            const { icon, name, symbol } = details;

            return (
              <li
                className="token-select-item d-flex justify-content-between align-items-center cursor-pointer"
                key={index}
                onClick={() => handleSelection(details)}
              >
                <div className="d-flex align-items-center">
                  <Icon name={icon} size={24} className="mr-3" />
                  <span className="text-main mr-2">{name}</span>
                </div>
                <span className="text-bold">{symbol}</span>
              </li>
            );
          })
        ) : internalTokenList?.length === 0 && tokenSelectionText ? (
          <li>{t("tokenSelect:noResults")}</li>
        ) : (
          <li>{t("tokenSelect:noTokensMessage")}</li>
        )}
      </ul>
    );
  };

  return (
    <div
      className={`${className ? className : ""} position-relative`}
      ref={dropdownRef}
    >
      {!tokenSelected?.name ? (
        <TextField
          className="token-select"
          icon="search"
          value={tokenSelectionText}
          onClick={() => setExpanded(true)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleTokenInputChange(event.target.value)
          }
          placeholder={t("tokenSelect:textField.placeholder")}
          autoFocus={!tokenSelected?.name && expanded}
          onBlurCapture={() => setHasError(!tokenSelected?.name)}
          error={
            error && hasError && !expanded
              ? t("tokenSelect:textField.errorMessage")
              : ""
          }
        >
          <div className="d-flex align-items-center">
            <Icon
              className={`arrow py-3 px-4 ${
                expanded ? "rotate-180" : "rotate-0"
              }`}
              name="chevron-down-0lp"
              size={20}
              onClick={handleDropdown}
            />
          </div>
        </TextField>
      ) : (
        <div
          className={`token-selected${
            !tokenIsNotWrapped ? " mb-4" : " error mb-2"
          }`}
        >
          <div
            className="d-flex justify-content-between align-items-center"
            onClick={() => {
              if (onTokenSelectedClick) {
                onTokenSelectedClick(tokenSelected);
              }
              setExpanded(true);
            }}
          >
            <div className="d-flex align-items-center">
              {tokenSelected?.icon && (
                <Icon name={tokenSelected.icon} size={24} className="mr-3" />
              )}
              <span>{tokenSelected.name}</span>
            </div>
            <div className="d-flex align-items-center">
              <span className="text-bold mr-5">{tokenSelected.symbol}</span>
              <div
                className="d-flex"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClearTokenSelected();
                }}
              >
                <Icon
                  name="cross-big"
                  size={12}
                  color="ui-main"
                  className="px-3 py-2 clear-icon"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {renderTokenList()}
      {tokenIsNotWrapped && (
        <div className="text-danger text-tiny ml-4">
          {t("tokenSelect:textField.wrappedTokenErrorMessage")}
        </div>
      )}
    </div>
  );
};

export default TokenSelect;
