import { useWeb3React } from "@web3-react/core";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Icon,
  LabelButton,
} from "@stichting-allianceblock-foundation/components";
import { BlockExplorerBadge } from "components/BlockExplorerBadge";
import { useGlobalContext } from "hooks/useGlobalContext";
import { getBlockExplorerNameByChainId } from "utils";

import "./ClaimButton.scss";

function ClaimButton({
  disabled,
  status,
  clicked,
  entry,
}: {
  disabled: boolean;
  status: string;
  clicked?: Dispatch<SetStateAction<string | undefined>>;
  entry: FormattedTransfer;
}) {
  const { sdk, currentNetwork, setClaimModal } = useGlobalContext();
  const { t } = useTranslation();

  const [buttonStatus, setButtonStatus] = useState<string>(status);
  const [transactionHash, setTransactionHash] = useState<string>("");

  const { library } = useWeb3React();

  const userWallet = library.getSigner();

  const badgeLabelName: string = getBlockExplorerNameByChainId(
    currentNetwork.chainId
  );

  useEffect(() => {
    parseClaimsAndCheckStatus();
  }, []);

  const parseClaimsAndCheckStatus = async () => {
    let claimsInProcess = (await sessionStorage.getItem(
      "claims-in-process"
    )) as any;
    if (typeof claimsInProcess === "string") {
      claimsInProcess = await JSON.parse(claimsInProcess);
    }
    if (claimsInProcess?.includes(entry.sourceTx)) {
      setButtonStatus(t("claimPage:buttonStatus.claiming"));
    }
  };

  const handleCollapse = async () => {
    setButtonStatus(t("claimPage:buttonStatus.claiming"));
    clicked ? clicked(t("claimPage:buttonStatus.claiming")) : null;

    let claimsInProcess = (await sessionStorage.getItem(
      "claims-in-process"
    )) as any;

    if (typeof claimsInProcess === "string") {
      claimsInProcess = await JSON.parse(claimsInProcess);
      claimsInProcess = claimsInProcess?.filter(
        (sourceTx: string) => sourceTx !== entry.sourceTx
      );
    }
    sessionStorage.setItem(
      "claims-in-process",
      JSON.stringify(claimsInProcess)
    );
  };

  function clearClaimFromSession(claimsInProcess: any) {
    const index = claimsInProcess.indexOf(entry.sourceTx, 0);
    if (index > -1) {
      claimsInProcess.splice(index, 1);
      sessionStorage.setItem(
        "claims-in-process",
        JSON.stringify(claimsInProcess)
      );
    }
  }

  const onClaimClick = async () => {
    await handleCollapse();
    const claimsFromStorage = sessionStorage.getItem("claims-in-process");

    if (typeof claimsFromStorage === "string") {
      const claimsInProcess = JSON.parse(claimsFromStorage) || [];
      claimsInProcess.push(entry.sourceTx);
      sessionStorage.setItem(
        "claims-in-process",
        JSON.stringify(claimsInProcess)
      );

      try {
        const unsignedTransaction = await entry.claimCallback();

        const sendTx = await userWallet.sendTransaction(unsignedTransaction);

        setTransactionHash(sendTx.hash);

        const sendTxReceipt = await sendTx.wait();

        if (sendTxReceipt.status === 1) {
          setButtonStatus(t("claimPage:buttonStatus.claimed"));
          clicked ? clicked(t("claimPage:buttonStatus.claimed")) : null;

          const tokenType = "ERC20";
          const tokenAddress =
            await sdk.legacyRouterContract.nativeToWrappedToken(
              entry.nativeChainId,
              entry.nativeToken
            );

          setClaimModal((prevState: any) => {
            return {
              ...prevState,
              claimData: {
                token: {
                  ...entry.bridgedToken,
                  address: tokenAddress,
                } as Token,
                type: tokenType,
                recipient: entry.recipient,
                transactionHash: sendTx.hash as string,
              },
              status: true,
            };
          });
          setButtonStatus(t("claimPage:buttonStatus.claimed"));
          clearClaimFromSession(claimsInProcess);
          return;
        }
        clearClaimFromSession(claimsInProcess);

        setButtonStatus(t("claimPage:buttonStatus.notClaimed"));
        clicked ? clicked(t("claimPage:buttonStatus.notClaimed")) : null;
      } catch (_) {
        clearClaimFromSession(claimsInProcess);
        setButtonStatus(t("claimPage:buttonStatus.notClaimed"));
        clicked ? clicked(t("claimPage:buttonStatus.notClaimed")) : null;
      }
    }
  };

  const claimButton = (
    <Button
      disabled={
        disabled || buttonStatus === t("claimPage:buttonStatus.claimed")
      }
      type="secondary"
      size="sm"
      onClick={onClaimClick}
      className="edit-button"
    >
      <div className="d-flex mr-2">
        <Icon
          name={
            buttonStatus === t("claimPage:buttonStatus.claimed")
              ? "edit-finalize"
              : "edit-claim"
          }
          color={"brand-primary"}
          size={16}
        />
      </div>

      <div className="claim-text">
        {buttonStatus === t("claimPage:buttonStatus.claimed")
          ? buttonStatus
          : t("claimPage:buttonStatus.claim")}
      </div>
    </Button>
  );

  const labelClaimingButton = (
    <LabelButton
      type="primary"
      size="sm"
      label={
        transactionHash ? (
          <BlockExplorerBadge
            title={badgeLabelName}
            hash={transactionHash}
            blockExplorer={currentNetwork.blockExplorerUrl}
            type="tx"
          />
        ) : (
          <p className="text-secondary">{badgeLabelName}</p>
        )
      }
      loading={true}
      loadingText={
        <span className="processing-span">{t("labelButton:processing")}</span>
      }
    >
      Claim
    </LabelButton>
  );

  return buttonStatus === t("claimPage:buttonStatus.claiming")
    ? labelClaimingButton
    : buttonStatus === t("claimPage:buttonStatus.notClaimed")
    ? claimButton
    : buttonStatus === t("claimPage:buttonStatus.claimed")
    ? claimButton
    : null;
}

export default ClaimButton;
