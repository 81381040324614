import { useWeb3React } from "@web3-react/core";
import React, { MutableRefObject, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Button,
  Dropdown,
  Icon,
} from "@stichting-allianceblock-foundation/components";
import MetamaskIcon from "assets/metamask.svg";
import { useBreakpoint } from "hooks/useBreakpoint";
import { useDetectOutsideClick } from "hooks/useDetectClickOutside";
import { useGlobalContext } from "hooks/useGlobalContext";
import { getNetworkIndex } from "utils/network";
import { ConnectorNames, connectorsByName } from "utils/web3React";

import "./WalletButton.scss";

const WalletButton = () => {
  const dropdownRef: MutableRefObject<null | any> = useRef(null);
  const {
    networkOptions,
    currentNetwork,
    setTriedConnecting,
    setIsWalletConnected,
    setNetworkIndex,
  } = useGlobalContext();
  const [expand, setExpand] = useDetectOutsideClick(dropdownRef, false);
  const { account, activate, connector, chainId, deactivate } = useWeb3React();
  const { greaterThan } = useBreakpoint();
  const { t } = useTranslation();

  function handleDisconnect() {
    deactivate();
    setIsWalletConnected(false);
    setExpand(false);
    setTriedConnecting(false);
  }

  async function handleConnectInjected() {
    await activate(connectorsByName[ConnectorNames.Injected]);
    setNetworkIndex(getNetworkIndex(currentNetwork?.chainId, networkOptions));
    setIsWalletConnected(true);
    setTriedConnecting(true);
    setExpand(!expand);
    const { ethereum } = window as any;
    ethereum?.removeAllListeners(["networkChanged"]);
  }

  function whatIsConnected(activeConnector: any) {
    if (activeConnector === connectorsByName[ConnectorNames.Injected]) {
      return (
        <>
          <div className="d-flex flex-column flex-md-row align-items-center justify-content-between py-3 mt-4">
            <span className="text-bold d-flex align-items-center">
              <img src={MetamaskIcon} alt="Metamask Icon" />
              Metamask
            </span>
            <Button
              type="secondary"
              onClick={() => handleDisconnect()}
              className="full-md-width"
            >
              {t("walletButton:button.disconnect")}
            </Button>
          </div>
        </>
      );
    } else {
      return <div>&nbsp;</div>;
    }
  }

  function dropDownSelection(account: string, chainId: number) {
    if (networkOptions.map((n) => n.chainId).includes(chainId)) {
      return (
        <div className="d-flex align-items-center" ref={dropdownRef}>
          <Dropdown
            expand={expand}
            label={greaterThan("md") ? account : ""}
            icon="wallet"
            onClick={() => setExpand(!expand)}
          >
            <div className="d-flex align-items-center text-main text-bold">
              <Icon name="info-circle" size={24} color="ui-main" />
              <span className="ml-3">{t("walletButton:dropdown.title")}</span>
            </div>
            <div className="text-secondary py-3">
              <Trans
                i18nKey="walletButton:dropdown.subtitle"
                components={{ span: <span /> }}
              />
            </div>
            {whatIsConnected(connector)}
          </Dropdown>
        </div>
      );
    } else {
      return (
        <div className="d-md-flex align-items-center" ref={dropdownRef}>
          <Dropdown
            expand={expand}
            label={greaterThan("md") ? t("walletButton:dropdown.title") : ""}
            alarm
            icon="power-button"
            onClick={() => setExpand(!expand)}
            fullText={true}
          >
            <div className="d-flex align-items-center text-main text-bold">
              <Icon name="info-circle" size={24} color="ui-main" />
              <span className="ml-3">{t("walletButton:dropdown.title")}</span>
            </div>
            <div className="text-secondary py-3">
              <Trans
                i18nKey="walletButton:dropdown.subtitle"
                components={{ span: <span /> }}
              />
            </div>
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between py-3 mt-4">
              <span className="text-bold d-flex align-items-center">
                <img className="mr-3" src={MetamaskIcon} alt="Metamask Icon" />
                Metamask
              </span>
              <Button
                type="primary"
                onClick={() => handleConnectInjected()}
                className="full-md-width"
              >
                {t("walletButton:button.connect")}
              </Button>
            </div>
          </Dropdown>
        </div>
      );
    }
  }

  return <>{dropDownSelection(account!, chainId!)}</>;
};

export default WalletButton;
