import React, { MutableRefObject, useEffect, useRef, useState } from "react";

import { Icon } from "@stichting-allianceblock-foundation/components";
import { Portal } from "components/Portal";
import { ModalContext } from "context/ModalContext";
import { useDetectOutsideClick } from "hooks/useDetectClickOutside";

import "./Modal.scss";

const Modal = ({
  title,
  subtitle,
  button,
  children,
  triggered = false,
  className,
}: ModalProps) => {
  const modalRef: MutableRefObject<null | any> = useRef(null);
  const [open, setOpen] = useDetectOutsideClick(modalRef, false);
  const [processingModal, setProcessingModal] = useState(false);

  useEffect(() => {
    setOpen(triggered);
  }, [triggered]);

  return (
    <ModalContext.Provider value={{ open, setOpen, setProcessingModal }}>
      {button}
      <Portal>
        {open && (
          <div className={`modal modal-backdrop${open ? " show" : ""}`}>
            <div ref={modalRef} className={`modal-dialog ${className ?? ""}`}>
              <div>
                {title && (
                  <div className="title-container">
                    <h2>{title}</h2>
                    <Icon
                      name="nav-cross"
                      size={20}
                      color="ui-main"
                      onClick={() => {
                        if (!processingModal) {
                          setOpen(false);
                        }
                      }}
                    />
                  </div>
                )}

                {subtitle && (
                  <div className="subtitle-container">
                    <p>{subtitle}</p>
                  </div>
                )}

                <div className="content-container">{children}</div>
              </div>
            </div>
          </div>
        )}
      </Portal>
    </ModalContext.Provider>
  );
};

export default Modal;
