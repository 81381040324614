import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";

import { getNetworkIndex } from "utils/network";
import { ConnectorNames, connectorsByName } from "utils/web3React";

import { useGlobalContext } from "./useGlobalContext";

export const useEagerConnect = () => {
  const { networkOptions, setCurrentNetwork, isWalletConnected } =
    useGlobalContext();
  const { activate, active, chainId } = useWeb3React();
  const { ethereum } = window as any;
  const [tried, setTried] = useState<boolean>(false);

  useEffect(() => {
    async function connectWallet() {
      const injectedConnector = connectorsByName[ConnectorNames.Injected];
      const isAuthorized: boolean = await injectedConnector.isAuthorized();
      try {
        if ((ethereum || isAuthorized) && isWalletConnected) {
          await activate(injectedConnector, undefined, true);
          const index = getNetworkIndex(Number(chainId!), networkOptions);
          setCurrentNetwork(networkOptions[index]);
          ethereum?.removeAllListeners(["networkChanged"]);
        } else {
          setTried(true);
        }
      } catch (e) {
        console.error("[Error trying to connect to the wallet]:", e);
        setTried(true);
      }
    }

    connectWallet();
  }, [isWalletConnected, chainId]);

  useEffect(() => {
    if (active) {
      setTried(true);
    }
  }, [active]);

  return tried;
};
