import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button, Icon } from "@stichting-allianceblock-foundation/components";
import { BlockExplorerBadge } from "components/BlockExplorerBadge";
import { useGlobalContext } from "hooks/useGlobalContext";
import { useModalContext } from "hooks/useModalContext";
import { getBlockExplorerNameByChainId } from "utils";
import { addAsset, getMetamaskProvider, isMetamask } from "utils/metamask";

import "./FaucetMiningSuccess.scss";

interface FaucetMiningSuccessProps {
  token: Token;
  tx: string;
  onClose: () => void;
}

const FaucetMiningSuccess = ({
  token,
  tx,
  onClose,
}: FaucetMiningSuccessProps) => {
  const { setOpen } = useModalContext();
  const { currentNetwork } = useGlobalContext();
  const { t } = useTranslation();

  const addTokenToMetamask = async (
    type: MetamaskAssetParamsType,
    options: MetamaskAssetParamsOptions
  ) => {
    try {
      const provider = getMetamaskProvider();
      await addAsset(type, options, provider);
    } catch (err) {
      console.error("An error occurred while trying to add the token.");
    }
  };

  useEffect(() => {
    return () => onClose();
  }, []);

  return (
    <div className="faucet-mining-success-modal">
      <div className="faucet-mining-success-modal-screen d-flex flex-column">
        <Icon
          className="align-self-end mr-5"
          name="nav-cross"
          size={20}
          color="ui-main-background"
          onClick={() => {
            setOpen(false);
            onClose();
          }}
        />
        <Icon
          className="align-self-center success-icon"
          size={32}
          name="check-circle"
          color="ui-main-background"
        />
      </div>
      <div className="faucet-mining-success-modal-content">
        <h1 className="text-center mt-3 mb-4">
          <Trans
            i18nKey="faucet:modal.title"
            components={{ span: <span /> }}
            values={{ tokenSymbol: token?.details?.symbol }}
          />
        </h1>
        <div className="d-flex align-items-center">
          <div className="text-small text-bold text-secondary mb-3 mr-3">
            {t("faucet:modal.transactionDetails")}
          </div>
        </div>
        <div className="px-5 mb-5 mt-3">
          <div className="d-flex align-items-center ml-2">
            <div>
              <div className="text-secondary">{t("faucet:modal.amount")}</div>
              <div className="text-main text-bold mr-2">1000</div>
            </div>
            <div className="ml-4">
              <div className="text-secondary">{t("faucet:modal.token")}</div>
              <div className="d-flex align-items-center">
                <Icon name={token?.details?.icon} size={18} className="mr-2" />
                <span className="text-uppercase text-main">
                  {token?.details?.symbol}
                </span>
              </div>
            </div>
          </div>
          {isMetamask() && (
            <div
              className="d-flex align-items-center cursor-pointer my-4"
              onClick={async () =>
                await addTokenToMetamask(
                  "ERC20" as unknown as MetamaskAssetParamsType,
                  {
                    address: token.address,
                    symbol: token.details.symbol,
                    decimals: token.details.decimals,
                  }
                )
              }
            >
              <Icon name="collapsed" size={20} />
              <span className="link-primary mx-2">
                {t("faucet:modal.addTokenToMetamask")}
              </span>
              <Icon name="metamask" size={20} />
            </div>
          )}
        </div>
        <div className="mb-5 mt-3">
          <div className="d-flex align-items-center">
            <Icon
              name="pass-through"
              size={20}
              color="ui-main"
              className="mr-2"
            />
            <span className="text-secondary">
              {t("faucet:modal.transaction")}
            </span>
          </div>
          <div className="d-flex align-items-center mt-2 mt-sm-0">
            <BlockExplorerBadge
              title={
                <Trans
                  i18nKey="faucet:modal.seeOnText"
                  components={{ span: <span /> }}
                  values={{
                    name: getBlockExplorerNameByChainId(currentNetwork.chainId),
                  }}
                />
              }
              hash={tx}
              type="tx"
              blockExplorer={currentNetwork.blockExplorerUrl}
              shorten
            />
          </div>
        </div>
        <div className="d-flex justify-content-sm-end mt-8">
          <Button
            type="primary"
            onClick={() => {
              setOpen(false);
              onClose();
            }}
          >
            <Icon name="cross" size={18} color="ui-border" />
            <span className="mr-2">{t("faucet:modal.closeBtn")}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FaucetMiningSuccess;
