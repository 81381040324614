import { InjectedConnector } from "@web3-react/injected-connector";

import { NETWORK_CONFIG } from "configs/networks";
import { ethers } from "ethers";

const POLLING_INTERVAL: number = 12000;
const SUPPORTED_CHAINIDS: number[] | undefined = Object.values(
  NETWORK_CONFIG
)?.map((config: any): number => config.network.chainId);

export const getLibrary = (provider: any): ethers.providers.Web3Provider => {
  const library = new ethers.providers.Web3Provider(provider, "any");
  library.pollingInterval = POLLING_INTERVAL;
  return library;
};

const injectedConnector = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAINIDS,
});

export enum ConnectorNames {
  Injected,
}

export const connectorsByName: {
  [connectorName in ConnectorNames]: any;
} = {
  [ConnectorNames.Injected]: injectedConnector,
};
