import React from "react";

import { Icon } from "@stichting-allianceblock-foundation/components";
import { Tooltip } from "components/Tooltips";

const InfoTooltip = ({ text, size = 16 }: InfoTooltipProps) => {
  return (
    <Tooltip text={text}>
      <Icon name="info-circle" size={size} color="ui-border" className="mb-3" />
    </Tooltip>
  );
};

export default InfoTooltip;
