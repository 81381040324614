import React from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Icon,
  Step,
  StepPanel,
} from "@stichting-allianceblock-foundation/components";
import { BlockExplorerBadge } from "components/BlockExplorerBadge";
import { InfoTooltip } from "components/Tooltips";
import { useGlobalContext } from "hooks/useGlobalContext";

interface StepBridgeReviewProps {
  activeStep: number;
  isServiceTokenApproved: boolean;
  isBridgeTokenApproved: boolean;
  txFeeEstimation: string;
  txFeeEstimationCurrency: string;
  nativeOrWrappedTokenSymbolToBridge: string;
}

const StepBridgeReview = ({
  activeStep,
  isServiceTokenApproved,
  isBridgeTokenApproved,
  txFeeEstimation,
  txFeeEstimationCurrency,
  nativeOrWrappedTokenSymbolToBridge,
}: StepBridgeReviewProps) => {
  const { currentNetwork, bridgeTransaction, serviceFeeOptions } =
    useGlobalContext();
  const { t } = useTranslation();

  return (
    <div className="step-review">
      <Step
        activeStep={activeStep}
        stepNumber={1}
        title={t("transferPage:stepReview.title")}
        subtitle={t("transferPage:stepReview.subtitle")}
      >
        <StepPanel>
          <div>
            <div className="d-flex align-items-center">
              <div className="text-small text-bold text-secondary mb-3 mr-3">
                {t("transferPage:stepReview.convertionDetailsField.title")}
              </div>
              <InfoTooltip
                text={t(
                  "transferPage:stepReview.convertionDetailsField.tooltip"
                )}
                size={18}
              />
            </div>
            <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between mt-3">
              <div>
                <div className="text-main text-bold mb-3">
                  {t(
                    "transferPage:stepReview.convertionDetailsField.networksFieldText"
                  )}
                </div>
                <div className="d-flex align-items-center">
                  <Icon name="arrow-target-down-long" size={32} />
                  <div>
                    <div className="d-flex align-items-center my-2">
                      <Icon
                        name={
                          bridgeTransaction?.network?.source?.chainIcon
                            ? bridgeTransaction.network.source.chainIcon
                            : "blank"
                        }
                        size={16}
                        className="mr-2"
                      />
                      <span className="text-main">
                        {bridgeTransaction?.network?.source?.chainName}
                      </span>
                    </div>
                    <div className="d-flex align-items-center my-2">
                      <Icon
                        name={
                          bridgeTransaction?.network?.target?.chainIcon
                            ? bridgeTransaction.network.target.chainIcon
                            : "blank"
                        }
                        size={16}
                        className="mr-2"
                      />
                      <span className="text-main">
                        {bridgeTransaction?.network?.target?.chainName}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 mt-md-0">
                <div className="text-main text-bold mb-3 d-flex justify-content-md-end">
                  {t(
                    "transferPage:stepReview.convertionDetailsField.tokensFieldText"
                  )}
                </div>
                <div className="d-flex align-items-center">
                  <Icon name="arrow-target-down-long" size={32} />
                  <div>
                    <div className="d-flex align-items-center my-2">
                      <span className="text-main text-bold">
                        {bridgeTransaction?.token?.amount}
                      </span>
                      <Icon
                        name={
                          bridgeTransaction?.token?.details?.icon
                            ? bridgeTransaction.token.details.icon
                            : "blank"
                        }
                        size={16}
                        className="mr-2 ml-3"
                      />
                      <span className="text-main">
                        {bridgeTransaction?.token?.details?.symbol}
                      </span>
                    </div>
                    <div className="d-flex align-items-center my-2">
                      <span className="text-main text-bold">
                        {bridgeTransaction?.token?.amount}
                      </span>
                      <Icon
                        name={
                          bridgeTransaction?.token?.details?.icon
                            ? bridgeTransaction.token.details.icon
                            : "blank"
                        }
                        size={16}
                        className="mr-2 ml-3"
                      />
                      <span className="text-main">
                        {nativeOrWrappedTokenSymbolToBridge}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StepPanel>
        <StepPanel>
          <div>
            <div className="d-flex align-items-center">
              <div className="text-small text-bold text-secondary mb-3 mr-3">
                {t("transferPage:stepReview.recipientField.title")}
              </div>
              <InfoTooltip
                text={t("transferPage:stepReview.recipientField.tooltip")}
                size={18}
              />
            </div>
            <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-sm-5 mt-sm-3">
              <div className="d-flex align-items-center">
                <Icon
                  name="wallet"
                  size={16}
                  color="ui-main"
                  className="mr-2"
                />
                <span className="text-main">
                  {t("transferPage:stepReview.recipientField.subtitle")}
                </span>
              </div>
              <div className="d-none d-sm-flex line flex-one mx-3"></div>
              <div className="d-flex align-items-center mt-2 mt-sm-0">
                <BlockExplorerBadge
                  hash={bridgeTransaction?.recipient}
                  blockExplorer={bridgeTransaction?.network?.target?.blockExplorerUrl}
                  type="address"
                  shorten
                />
              </div>
            </div>
          </div>
        </StepPanel>
        <StepPanel>
          <div>
            <div className="d-flex align-items-center">
              <div className="text-small text-bold text-secondary mb-3 mr-3">
                {t("transferPage:stepReview.transactionFeesField.title")}
              </div>
              <InfoTooltip
                text={t("transferPage:stepReview.transactionFeesField.tooltip")}
                size={18}
              />
            </div>
            <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-3 mt-2 mb-sm-5 mt-sm-3">
              <div className="d-flex align-items-center">
                <Icon
                  name="pass-through"
                  size={16}
                  color="ui-main"
                  className="mr-2"
                />
                <span className="text-main">
                  {t(
                    "transferPage:stepReview.transactionFeesField.bridgeFeesText"
                  )}
                </span>
              </div>
              <div className="d-none d-sm-flex line flex-one mx-3"></div>
              <div className="d-flex align-items-center">
                <span className="text-main text-bold mr-2 mr-sm-4">
                  {bridgeTransaction?.feeToken?.amount}
                </span>
                <div className="line d-flex d-sm-none flex-one mr-2 mr-sm-0"></div>
                <div className="d-flex flex-column flex-sm-row">
                  <div className="d-flex mt-3 mt-md-0">
                    <Icon
                      name={
                        bridgeTransaction?.feeToken?.details?.icon
                          ? bridgeTransaction.feeToken.details.icon
                          : "blank"
                      }
                      size={18}
                      className="mr-2"
                    />
                    <span className="text-uppercase text-secondary">
                      {bridgeTransaction?.feeToken?.details?.symbol}
                    </span>
                  </div>
                  <span className="text-secondary ml-2">
                    {
                      serviceFeeOptions.filter(
                        (serviceFee: ServiceFeeToken) =>
                          serviceFee?.address ===
                          bridgeTransaction?.feeToken?.address
                      )[0]?.amountInCurrency
                    }
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-3 mt-2 mb-sm-5 mt-sm-3">
              <div className="d-flex align-items-center">
                <Icon
                  name="buying-fees"
                  size={16}
                  color="ui-main"
                  className="mr-2"
                />
                <div>
                  <span className="text-main">
                    {t(
                      "transferPage:stepReview.transactionFeesField.estimationFeesText"
                    )}
                  </span>
                  <span className="text-main text-bold ml-2">
                    {!isServiceTokenApproved
                      ? `[${t("transferPage:approveButton", {
                          tokenSymbol:
                            bridgeTransaction?.feeToken?.details?.symbol,
                        })}]`
                      : !isBridgeTokenApproved &&
                        bridgeTransaction?.feeToken?.address !==
                          bridgeTransaction?.token?.address
                      ? `[${t("transferPage:approveButton", {
                          tokenSymbol:
                            bridgeTransaction?.token?.details?.symbol,
                        })}]`
                      : `[${t("transferPage:transferButton")}]`}
                  </span>
                </div>
              </div>
              <div className="d-none d-sm-flex line flex-one mx-3"></div>
              <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                <span className="text-main text-bold mr-2 mr-sm-4">
                  {txFeeEstimation}
                </span>
                <div className="line d-flex d-sm-none flex-one mr-2 mr-sm-0"></div>
                <div className="d-flex flex-column flex-sm-row">
                  <div className="d-flex mt-3 mt-md-0">
                    <Icon
                      name={currentNetwork?.chainIcon}
                      size={18}
                      className="mr-2"
                    />
                    <span className="text-uppercase text-secondary">
                      {currentNetwork?.nativeCurrency?.symbol}
                    </span>
                  </div>
                  <span className="text-secondary ml-2">
                    {txFeeEstimationCurrency}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </StepPanel>
        <div>
          <StepPanel>
            <div className="d-flex mx-2 mt-5 mb-6">
              <Icon
                name="info-circle"
                size={20}
                color="ui-main"
                className="mr-4"
              />
              <div>
                <p className="text-bold">
                  {t("transferPage:attentionField.title")}
                </p>
                <p className="text-align-justify">
                  <Trans
                    i18nKey="transferPage:attentionField.subtitle"
                    components={{ span: <span /> }}
                  />
                </p>
              </div>
            </div>
          </StepPanel>
        </div>
      </Step>
    </div>
  );
};

export default StepBridgeReview;
