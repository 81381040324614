import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Icon,
  Slider,
  Step,
  StepPanel,
  TextField,
  TokenInput,
} from "@stichting-allianceblock-foundation/components";
import { Select } from "components/Select";
import { TokenSelect } from "components/TokenSelect";
import { InfoTooltip } from "components/Tooltips";
import { TOKEN_SLIDER_PERCENTAGE_DECIMALS } from "configs/constants";
import { NETWORK_CONFIG } from "configs/networks";
import { useGlobalContext } from "hooks/useGlobalContext";
import {
  calculateTokenAmountByPercentage,
  calculateTokenPercentageByAmount,
  formatTokenInput,
  getTokenIcon,
  isAddress,
  svgUrlExists,
} from "utils";
import { getNetworkIndex } from "utils/network";

import StepBridgeNotifications from "./StepBridgeNotifications";

interface StepBridgeDetailsProps {
  activeStep: number;
  wasTransactionCleared: boolean;
  tokenSelectedBalance: string;
  tokenList: TokenDetails[];
  selectedToken: TokenDetails;
  sliderPercentage: number;
  isServiceTokenApproved: boolean;
  isBridgeTokenApproved: boolean;
  serviceFeeTokenIndex: number;
  txFeeEstimation: string;
  txFeeEstimationCurrency: string;
  notEnoughBalanceForServiceFee: boolean;
  isBridgeTransactionTokenNativeToken: boolean;
  isTokenEWTB: boolean;
  notDeployedWrappedToken: boolean;
  tokenIsNotWrapped: boolean;
  updateSliderPercentage: (percentage: number) => void;
  updateSelectedToken: (option: TokenDetails) => void;
  updateTokenSelectedBalance: (tokenBalance: string) => void;
  updateTokenList: (options: TokenDetails[]) => void;
  onSelectedFeeTokenChange: (option: ServiceFeeToken) => void;
}

const StepBridgeDetails = ({
  activeStep,
  wasTransactionCleared,
  tokenSelectedBalance,
  tokenList,
  selectedToken,
  sliderPercentage,
  isServiceTokenApproved,
  isBridgeTokenApproved,
  serviceFeeTokenIndex,
  txFeeEstimation,
  txFeeEstimationCurrency,
  notEnoughBalanceForServiceFee,
  isBridgeTransactionTokenNativeToken,
  isTokenEWTB,
  notDeployedWrappedToken,
  tokenIsNotWrapped,
  updateSliderPercentage,
  updateSelectedToken,
  updateTokenSelectedBalance,
  updateTokenList,
  onSelectedFeeTokenChange,
}: StepBridgeDetailsProps) => {
  const {
    sdk,
    currentNetwork,
    networkOptions,
    bridgeTransaction,
    serviceFeeOptions,
    setBridgeTransaction,
  } = useGlobalContext();
  const { account, chainId } = useWeb3React();
  const { t } = useTranslation();
  const [isLoadingTokens, setIsLoadingTokens] = useState<boolean>(false);
  const [targetNetworkIndex, setTargetNetworkIndex] = useState(0);
  const [nativeChain, setNativeChain] = useState<Network>({} as Network);
  const [tokenInputAmount, setTokenInputAmount] = useState("0");
  const [resetFlag, setResetFlag] = useState<boolean>(false);

  const maxAttempts: number = 10;

  const onChangeRecipient = (recipient: string) => {
    setBridgeTransaction({
      ...bridgeTransaction,
      recipient: recipient,
    });
  };

  const handleTokenInputAmount = (amount: string) => {
    if (amount) {
      const percentage = calculateTokenPercentageByAmount(
        amount,
        tokenSelectedBalance,
        bridgeTransaction.token.details.decimals
      );

      const newAmount =
        Number(amount) >= 0 && Number(amount) <= Number(tokenSelectedBalance)
          ? amount
          : tokenSelectedBalance;

      updateSliderPercentage(
        percentage > 100 ? 100 : percentage < 0 ? 0 : percentage
      );
      setTokenInputAmount(formatTokenInput(newAmount));
      setBridgeTransaction({
        ...bridgeTransaction,
        token: {
          details: bridgeTransaction.token.details,
          address: bridgeTransaction.token.address,
          amount: formatTokenInput(newAmount),
        },
      });
    } else {
      setTokenInputAmount("0");
      updateSliderPercentage(0);
      setBridgeTransaction({
        ...bridgeTransaction,
        token: {
          details: bridgeTransaction.token.details,
          address: bridgeTransaction.token.address,
          amount: "0",
        },
      });
    }
  };

  const handleSliderPercentage = (percentage: string) => {
    const amount = calculateTokenAmountByPercentage(
      percentage,
      tokenSelectedBalance,
      bridgeTransaction.token.details.decimals
    );

    updateSliderPercentage(Number(percentage));
    setTokenInputAmount(Number(amount) > 0 ? formatTokenInput(amount) : "0");
    setBridgeTransaction({
      ...bridgeTransaction,
      token: {
        details: bridgeTransaction.token.details,
        address: bridgeTransaction.token.address,
        amount: Number(amount) > 0 ? formatTokenInput(amount) : "0",
      },
    });
  };

  const onChangeTargetNetwork = (targetNetwork: Network) => {
    setTargetNetworkIndex(
      getNetworkIndex(
        targetNetwork.chainId,
        networkOptions.filter(
          (network) =>
            network.chainId !== bridgeTransaction?.network?.source?.chainId
        )
      )
    );
    setBridgeTransaction({
      ...bridgeTransaction,
      network: {
        source: bridgeTransaction.network.source,
        target: targetNetwork,
      },
    });
  };

  const onChangeSelectToken = (option: TokenDetails) => {
    updateSelectedToken(option);
    setTokenInputAmount("0");
    setBridgeTransaction({
      ...bridgeTransaction,
      token: {
        details: {
          name: option.name,
          symbol: option.symbol,
          decimals: option.decimals || 0,
          icon: option.icon || "",
        },
        amount: bridgeTransaction.token.amount,
        address: option.address || "",
      },
    });
  };

  const handleClearTokenSelected = () => {
    updateSelectedToken({} as TokenDetails);
    setTokenInputAmount("0");
    setBridgeTransaction({
      ...bridgeTransaction,
      token: {
        details: {
          name: "",
          symbol: "",
          decimals: 0,
          icon: "",
        },
        amount: "0",
        address: "",
      },
    });
    updateTokenSelectedBalance("0");
    updateSliderPercentage(0);
  };

  useEffect(() => {
    const resetTokenInputAmount = () => {
      setTokenInputAmount("0");
      updateSliderPercentage(0);
      setBridgeTransaction({
        ...bridgeTransaction,
        token: {
          details: bridgeTransaction.token.details,
          address: bridgeTransaction.token.address,
          amount: "0",
        },
      });
    };

    resetTokenInputAmount();
  }, [account, chainId]);

  useEffect(() => {
    const loadTransactionNetworkSource = () => {
      setBridgeTransaction({
        ...bridgeTransaction,
        network: {
          source: currentNetwork,
          target: bridgeTransaction.network.target,
        },
      });
      setTargetNetworkIndex(0);
    };

    loadTransactionNetworkSource();
  }, [currentNetwork]);

  useEffect(() => {
    const forceLoadTransactionNetworkSource = () => {
      if (
        !bridgeTransaction?.network?.source?.chainId ||
        bridgeTransaction?.network?.source?.chainId !== currentNetwork?.chainId
      ) {
        setBridgeTransaction({
          ...bridgeTransaction,
          network: {
            source: currentNetwork,
            target: bridgeTransaction.network.target,
          },
        });
      }
    };

    forceLoadTransactionNetworkSource();
  }, [bridgeTransaction, currentNetwork?.chainId]);

  useEffect(() => {
    let attemptCount: number = 0;
    const loadPreselectedTokens = async () => {
      setIsLoadingTokens(true);
      if (
        sdk &&
        account &&
        currentNetwork?.chainId &&
        Object.values(NETWORK_CONFIG)[0].network.chainId !==
          currentNetwork?.chainId
      ) {
        const albtToken: TokenDetails = await sdk.getALBTTokenLegacy();
        const preselectedTokens_ = {
          [albtToken.symbol]: albtToken.address,
          /* ...Object.values(NETWORK_CONFIG)?.filter(
            (item: any) => item?.network?.chainId === currentNetwork.chainId
          )[0].tokenAddresses, */
        };
        const preselectedTokenList: TokenDetails[] = [];

        const preselectedTokens = await sdk.getTokenDetailsMulticall(
          preselectedTokens_,
          account
        );
        if (preselectedTokens?.length) {
          for (const preselectedToken of preselectedTokens) {
            try {
              const tokenIcon = getTokenIcon(preselectedToken);
              preselectedTokenList.push({
                ...preselectedToken,
                icon: svgUrlExists(`/icons/${tokenIcon}.svg`)
                  ? tokenIcon
                  : "assets",
              });
            } catch (err) {
              console.error(err);
              updateTokenList([]);
              // if network change, we need to end the function to avoid
              // sending unnecessary requests to sdk
              return;
            }
          }
        }

        updateTokenList(preselectedTokenList);
      }
      setIsLoadingTokens(false);
    };
    try {
      loadPreselectedTokens();
    } catch (error) {
      attemptCount++;
      console.error(error);
      if (attemptCount < maxAttempts) {
        loadPreselectedTokens();
      }
    }

    return () => {
      setIsLoadingTokens(false);
      updateTokenList([]);
    };
  }, [sdk, account]);

  useEffect(() => {
    let attemptCount: number = 0;
    const loadBridgeTransactionTokenNativeChainNetwork = async () => {
      if (sdk && bridgeTransaction?.token?.address && selectedToken?.address) {
        const nativeToken: NativeTokenWithChainId =
          await sdk.getNativeTokenByWrappedAddressLegacy(
            bridgeTransaction.token.address
          );

        if (nativeToken?.chainId !== 0) {
          const nativeChainNetwork: Network = networkOptions.filter(
            (network) => network.chainTargetId === nativeToken.chainId
          )[0];
          setNativeChain(nativeChainNetwork);
          onChangeTargetNetwork(nativeChainNetwork);
          setResetFlag(true);
        } else {
          setNativeChain(bridgeTransaction?.network?.source);
        }
      } else {
        setResetFlag(false);
      }
    };

    const clearNativeChainIfNotSelectedToken = () => {
      if (!bridgeTransaction?.token?.address && !selectedToken?.address) {
        setNativeChain({} as Network);
      }
    };

    try {
      loadBridgeTransactionTokenNativeChainNetwork();
    } catch (error) {
      attemptCount++;
      console.error(error);
      if (attemptCount < maxAttempts) {
        loadBridgeTransactionTokenNativeChainNetwork();
      }
    }
    clearNativeChainIfNotSelectedToken();
  }, [sdk, selectedToken?.address, bridgeTransaction?.token?.address]);

  return (
    <div className={`step-details${activeStep !== 0 ? " mb-5" : ""}`}>
      <Step
        activeStep={activeStep}
        stepNumber={0}
        title={t("transferPage:stepDetails.title")}
        subtitle={t("transferPage:stepDetails.subtitle")}
      >
        <StepPanel>
          <div className="d-flex flex-column flex-lg-row justify-content-lg-between pb-3">
            <div className="width-100">
              <div className="d-flex align-items-center">
                <div className="text-small text-bold text-secondary mb-3 mr-3">
                  {t(
                    "transferPage:stepDetails.networkField.sourceNetwork.title"
                  )}
                </div>
                <InfoTooltip
                  text={t(
                    "transferPage:stepDetails.networkField.sourceNetwork.tooltip"
                  )}
                  size={18}
                />
              </div>
              <Select
                optionsList={[currentNetwork]}
                displayFields={{
                  primary: "chainName",
                  secondary: "",
                  icon: "chainIcon",
                }}
                size="sm"
                selected={0}
                disabled={true}
              />
            </div>
            <div className="d-flex justify-content-center align-items-lg-end mt-6 mb-4 mt-lg-0 mb-lg-3 mx-lg-5">
              <Icon
                className="rotate-90 rotate-lg-0"
                name="bridge-token-transfer"
                size={24}
                color="ui-main"
              />
            </div>
            <div className="width-100 z-index-7">
              <div className="d-flex align-items-center">
                <div className="text-small text-bold text-secondary mb-3 mr-3">
                  {t(
                    "transferPage:stepDetails.networkField.targetNetwork.title"
                  )}
                </div>
                <InfoTooltip
                  text={t(
                    "transferPage:stepDetails.networkField.targetNetwork.tooltip"
                  )}
                  size={18}
                />
              </div>
              <Select
                className="target-network-dropdown-list"
                optionsList={networkOptions.filter(
                  (network) =>
                    network.chainId !==
                    bridgeTransaction?.network?.source?.chainId
                )}
                displayFields={{
                  primary: "chainName",
                  secondary: "",
                  icon: "chainIcon",
                }}
                size="sm"
                selected={targetNetworkIndex}
                placeholder={{
                  text: t(
                    "transferPage:stepDetails.networkField.targetNetwork.placeholderText"
                  ),
                  icon: "network",
                  reset: wasTransactionCleared,
                  active:
                    bridgeTransaction?.network?.source?.chainId ===
                    currentNetwork?.chainId,
                }}
                disabled={true}
                onSelectOptionChange={onChangeTargetNetwork}
                resetFlag={resetFlag}
              />
            </div>
          </div>
        </StepPanel>
        <StepPanel>
          <div className="d-flex flex-column mb-4">
            <div className="d-flex align-items-center">
              <div className="text-small text-bold text-secondary mb-3 mr-3">
                {t("transferPage:stepDetails.tokenSelectField.title")}
              </div>
              <InfoTooltip
                text={t("transferPage:stepDetails.tokenSelectField.tooltip")}
                size={18}
              />
            </div>
            <TokenSelect
              className="z-index-6"
              tokenList={tokenList}
              tokenSelected={selectedToken}
              onChange={onChangeSelectToken}
              onTokenSelectedClick={handleClearTokenSelected}
              onClear={handleClearTokenSelected}
              loading={isLoadingTokens}
              tokenIsNotWrapped={tokenIsNotWrapped}
              error={tokenIsNotWrapped}
            />
          </div>
          <div
            className={`fade-in-400 ${
              nativeChain?.chainId ? "d-block" : "d-none"
            }`}
          >
            <div className="d-flex align-items-center">
              <div className="text-small text-bold text-secondary mb-3 mr-3">
                {t("transferPage:stepDetails.inputSliderField.title")}
              </div>
              <InfoTooltip
                text={t("transferPage:stepDetails.inputSliderField.tooltip")}
                size={18}
              />
            </div>
            <Select
              optionsList={[nativeChain]}
              displayFields={{
                primary: "chainName",
                secondary: "",
                icon: "chainIcon",
              }}
              size="sm"
              selected={0}
              disabled={true}
              placeholder={{
                text: "Native Chain",
                icon: "network",
                reset: wasTransactionCleared,
                active: !bridgeTransaction?.token?.address,
              }}
            />
          </div>
          <div className="mt-7">
            <TokenInput
              balance={tokenSelectedBalance}
              icon={`${location?.origin}/icons/${
                bridgeTransaction?.token?.details?.icon
                  ? bridgeTransaction.token.details.icon
                  : "blank"
              }.svg`}
              token={bridgeTransaction?.token?.details?.symbol}
              label={
                bridgeTransaction?.token?.amount
                  ? t("transferPage:stepDetails.inputSliderField.labelAmount")
                  : ""
              }
              value={tokenInputAmount}
              onChange={handleTokenInputAmount}
              disabled={
                !(Number(tokenSelectedBalance) > 0) && tokenIsNotWrapped
              }
              error={
                !!selectedToken?.name &&
                Number(tokenSelectedBalance) === 0 &&
                !tokenIsNotWrapped
                  ? t("transferPage:stepDetails.inputSliderField.noBalance", {
                      tokenSymbol: selectedToken?.symbol,
                    })
                  : ""
              }
            />
            <Slider
              color="primary"
              step={1 / 10 ** TOKEN_SLIDER_PERCENTAGE_DECIMALS}
              value={sliderPercentage}
              onChange={handleSliderPercentage}
              disabled={
                !(Number(tokenSelectedBalance) > 0) && tokenIsNotWrapped
              }
            />
          </div>
        </StepPanel>
        <StepPanel>
          <div>
            <div className="d-flex align-items-center">
              <div className="text-small text-bold text-secondary mb-3 mr-3">
                {t("transferPage:stepDetails.recipientField.title")}
              </div>
              <InfoTooltip
                text={t("transferPage:stepDetails.recipientField.tooltip")}
                size={18}
              />
            </div>
            <TextField
              icon={"wallet"}
              label={
                bridgeTransaction?.recipient
                  ? ""
                  : t("transferPage:stepDetails.recipientField.title")
              }
              value={bridgeTransaction?.recipient ?? ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeRecipient(e.target.value)
              }
              disabled={!!selectedToken?.address && tokenIsNotWrapped}
            />
            {!isAddress(bridgeTransaction?.recipient) && (
              <p className="text-danger">
                {t(
                  "transferPage:stepDetails.recipientField.invalidAddressText"
                )}
              </p>
            )}
          </div>
        </StepPanel>
        <StepPanel>
          <div>
            <div className="d-flex align-items-center">
              <div className="text-small text-bold text-secondary mb-3 mr-3">
                {t("transferPage:stepDetails.transactionFeesFieldStep.title")}
              </div>
              <InfoTooltip
                text={t(
                  "transferPage:stepDetails.transactionFeesFieldStep.tooltip"
                )}
                size={18}
              />
            </div>
            <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-sm-5 mt-sm-3">
              <div className="d-flex align-items-center mb-3 mb-sm-0">
                <Icon
                  name="pass-through"
                  size={18}
                  color="ui-main"
                  className="mr-2"
                />
                <span className="text-main">
                  {t(
                    "transferPage:stepDetails.transactionFeesFieldStep.bridgeFeesText"
                  )}
                </span>
              </div>
              <div className="line d-none d-sm-flex flex-one mx-3"></div>
              <div className="mb-5 mb-sm-0">
                {serviceFeeOptions.length > 0 ? (
                  <div className="d-flex flex-column flex-sm-row align-items-sm-center width-100 width-sm-auto">
                    <span className="text-main text-bold text-center my-2 my-sm-0 mr-4">
                      {bridgeTransaction?.feeToken?.amount}
                    </span>
                    <Select
                      optionsList={serviceFeeOptions}
                      displayFields={{
                        primary: "symbol",
                        secondary: "amountInCurrency",
                        icon: "icon",
                      }}
                      size="sm"
                      selected={serviceFeeTokenIndex}
                      onSelectOptionChange={onSelectedFeeTokenChange}
                      disabled={!!selectedToken?.address && tokenIsNotWrapped}
                    />
                  </div>
                ) : (
                  <div className="pulse d-flex justify-content-center justify-content-sm-start align-items-center mt-3 mt-sm-0">
                    <Icon name="spinner" size={18} className="mr-3" />
                    <span>
                      {t(
                        "transferPage:stepDetails.transactionFeesFieldStep.bridgeFeesLoadingText"
                      )}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-sm-5 mt-sm-3">
              <div className="d-flex align-items-center">
                <Icon
                  name="buying-fees"
                  size={16}
                  color="ui-main"
                  className="mr-2"
                />
                <div>
                  <span className="text-main">
                    {t(
                      "transferPage:stepDetails.transactionFeesFieldStep.estimationFeesText"
                    )}
                  </span>
                  {bridgeTransaction?.network?.target?.chainId &&
                    bridgeTransaction?.token?.address &&
                    !["0", "0.0"]?.includes(bridgeTransaction?.token?.amount) &&
                    bridgeTransaction?.recipient && (
                      <span className="text-main text-bold ml-2">
                        {!isServiceTokenApproved
                          ? `[${t("transferPage:approveButton", {
                              tokenSymbol:
                                bridgeTransaction?.feeToken?.details?.symbol,
                            })}]`
                          : !isBridgeTokenApproved &&
                            bridgeTransaction?.feeToken?.address !==
                              bridgeTransaction?.token?.address
                          ? `[${t("transferPage:approveButton", {
                              tokenSymbol:
                                bridgeTransaction?.token?.details?.symbol,
                            })}]`
                          : `[${t("transferPage:transferButton")}]`}
                      </span>
                    )}
                </div>
              </div>
              <div className="line d-none d-sm-flex flex-one mx-sm-3"></div>
              <div className="d-flex justify-content-sm-start align-items-center mt-4 mt-sm-0">
                <span className="text-main text-bold mr-4">
                  {txFeeEstimation}
                </span>
                <div className="line d-flex d-sm-none flex-one  mr-4 mr-sm-0"></div>
                <div className="d-flex flex-column flex-md-row">
                  <div className="d-flex">
                    <Icon
                      name={currentNetwork?.chainIcon}
                      size={18}
                      className="mr-2"
                    />
                    <span className="text-uppercase text-secondary">
                      {currentNetwork?.nativeCurrency?.symbol}
                    </span>
                  </div>
                  <span className="text-secondary ml-2">
                    {txFeeEstimationCurrency}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </StepPanel>
        <div>
          <StepPanel>
            <StepBridgeNotifications
              notEnoughBalanceForServiceFee={notEnoughBalanceForServiceFee}
              isBridgeTransactionTokenNativeToken={
                isBridgeTransactionTokenNativeToken
              }
              isTokenEWTB={isTokenEWTB}
              notDeployedWrappedToken={notDeployedWrappedToken}
            />
            <div className="d-flex mx-2 mt-5 mb-6">
              <Icon
                name="info-circle"
                size={20}
                color="ui-main"
                className="mr-4"
              />
              <div>
                <p className="text-bold">
                  {t("transferPage:attentionField.title")}
                </p>
                <p className="text-align-justify">
                  <Trans
                    i18nKey="transferPage:attentionField.subtitle"
                    components={{ span: <span /> }}
                  />
                </p>
              </div>
            </div>
          </StepPanel>
        </div>
      </Step>
    </div>
  );
};

export default StepBridgeDetails;
