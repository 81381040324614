import React, { useEffect, useMemo, useState } from "react";

import { ReactComponent as ClaimEmptySvgDesktop } from "assets/claim-empty-desktop.svg";
import { ReactComponent as ClaimEmptySvgMobile } from "assets/claim-empty-mobile.svg";
import { ReactComponent as ClaimEmptyLoading } from "assets/claim-loading.svg";
import { useBreakpoint } from "hooks/useBreakpoint";
import useDefaultFilterOptionData from "hooks/useDefaultFilterOption";
import { useGlobalContext } from "hooks/useGlobalContext";

import Pagination from "../../../Pagination/Pagination";
import Attention from "./AttentionMessageComponent/Attention";
import ClaimHeader from "./ClaimHeader/ClaimHeader";
import ClaimingListingMobile from "./ClaimListingMobile/ClaimingListingMobile";
import ClaimListingWeb from "./ClaimListingWeb/ClaimListingWeb";
import EmptyClaimsFooter from "./EmptyClaimsFooter/EmptyClaimsFooter";

import "./ClaimListing.scss";

const ClaimListing = ({ claimData }: { claimData?: FormattedTransfer[] }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { claims, isClaimLoaded, setIsClaimLoaded, setFilterCriteria } =
    useGlobalContext();
  const { greaterThan } = useBreakpoint();
  const defaultFilterOption = useDefaultFilterOptionData();
  const PageSize = 10;

  const currentClaimData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return claimData?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, claimData]);

  const isMobile: boolean = !greaterThan("sm");

  useEffect(() => {
    return () => {
      setFilterCriteria(defaultFilterOption);
      setIsClaimLoaded(false);
    };
  }, [claims]);

  return (
    <div className="claim-listing">
      {!isMobile ? <ClaimHeader /> : null}
      <div className="claim-wrappers">
        <div
          className={`claim-table${
            isClaimLoaded ? " d-flex" : ""
          } align-items-center justify-content-center mt-3 mt-md-0`}
        >
          {!isClaimLoaded && currentClaimData?.length ? (
            currentClaimData.map((entry: FormattedTransfer, i: number) =>
              isMobile ? (
                <ClaimingListingMobile
                  entry={entry}
                  key={entry.globalHash + i}
                />
              ) : (
                <ClaimListingWeb
                  entry={entry}
                  key={entry.globalHash + i}
                ></ClaimListingWeb>
              )
            )
          ) : isClaimLoaded ? (
            <ClaimEmptyLoading />
          ) : (
            <div className="no-claims-wrapper d-flex justify-content-center">
              {!claims.length || currentClaimData?.length === 0 ? (
                isMobile ? (
                  <ClaimEmptySvgMobile />
                ) : (
                  <ClaimEmptySvgDesktop />
                )
              ) : (
                <ClaimEmptyLoading />
              )}
            </div>
          )}
        </div>
        {!currentClaimData?.length ? null : (
          <>
            <Attention />

            {claimData && claimData.length > PageSize ? (
              <Pagination
                className="d-flex justify-content-center align-items-center v"
                pageSize={PageSize}
                currentPage={currentPage}
                totalCount={claimData?.length}
                onPageChange={(page: number) => {
                  setCurrentPage(page);
                }}
              ></Pagination>
            ) : null}
          </>
        )}
      </div>
      {!currentClaimData?.length && !isClaimLoaded ? (
        <EmptyClaimsFooter />
      ) : null}
    </div>
  );
};

export default ClaimListing;
