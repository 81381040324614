export const config: NetworkConfig = {
  eth: {
    network: {
      symbol: "ETH",
      chainId: 1,
      chainName: "Ethereum",
      chainIcon: "ethereum",
      chainTargetId: 1,
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrl: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
      blockExplorerUrl: "https://etherscan.io",
      refreshClaimTimestamp: 15000,
    },
    tokenAddresses: {},
  },
  bsc: {
    network: {
      symbol: "BSC",
      chainId: 56,
      chainName: "Smart Chain",
      chainIcon: "binance",
      chainTargetId: 2,
      nativeCurrency: {
        name: "BNB",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrl: "https://bsc-dataseed.binance.org/",
      blockExplorerUrl: "https://bscscan.com",
      refreshClaimTimestamp: 15000,
    },
    tokenAddresses: {
      WEWT: "0x6602267589a2a952316189b6ecae9e5eb117bbd5",
      WEJS: "0x09f423aC3C9baBBfF6F94D372b16E4206e71439f",
    },
  },
  avalanche: {
    network: {
      symbol: "AVAX",
      chainId: 43114,
      chainName: "Avalanche C-Chain",
      chainIcon: "avalanche",
      chainTargetId: 3,
      nativeCurrency: {
        name: "AVAX",
        symbol: "AVAX",
        decimals: 18,
      },
      rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
      blockExplorerUrl: "https://cchain.explorer.avax.network",
      refreshClaimTimestamp: 15000,
    },
    tokenAddresses: {
      WEWT: "0xd96d422B742405C48112269F03f11F42b3C211e2",
    },
  },
  polygon: {
    network: {
      symbol: "MATIC",
      chainId: 137,
      chainName: "Polygon Matic",
      chainIcon: "matic",
      chainTargetId: 4,
      nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
      },
      rpcUrl: "https://rpc-mainnet.maticvigil.com",
      blockExplorerUrl: "https://polygonscan.com",
      refreshClaimTimestamp: 15000,
    },
    tokenAddresses: {
      WEWT: "0x6db7f16d8dd1e120acf2965b8c86d8bdec8c4add",
    },
  },
  ewc: {
    network: {
      symbol: "EWC",
      chainId: 246,
      chainName: "Energy Web Chain",
      chainIcon: "ewc",
      chainTargetId: 5,
      nativeCurrency: {
        name: "EWT",
        symbol: "EWT",
        decimals: 18,
      },
      rpcUrl: "https://rpc.energyweb.org",
      blockExplorerUrl: "https://explorer.energyweb.org",
      refreshClaimTimestamp: 15000,
    },
    tokenAddresses: {
      WEWT: "0x0DCF00f9223DCb4d4CE73BF08D1050ee8C5A143D",
    },
    EWTB: {
      ETH: "0x178c820f862B14f316509ec36b13123DA19A6054",
    },
  },
  arbitrum: {
    network: {
      symbol: "ARBITRUM",
      chainId: 42161,
      chainName: "Arbitrum",
      chainIcon: "arbitrum",
      chainTargetId: 6,
      nativeCurrency: {
        name: "AETH",
        symbol: "AETH",
        decimals: 18,
      },
      rpcUrl: "https://arb1.arbitrum.io/rpc",
      blockExplorerUrl: "https://arbiscan.io",
      refreshClaimTimestamp: 15000,
    },
    tokenAddresses: {
      WEWT: "0xef11d4c056ec3e3b4c00a555f7cf08b9bbe6422b",
    },
  },
  optimistic: {
    network: {
      symbol: "OPTIMISTIC",
      chainId: 10,
      chainName: "Optimistic",
      chainIcon: "optimistic",
      chainTargetId: 7,
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrl: "https://mainnet.optimism.io",
      blockExplorerUrl: "https://optimistic.etherscan.io",
      refreshClaimTimestamp: 15000,
    },

    tokenAddresses: {
      WEWT: "0x6c6c4017547120cdd18898abd104e9574a66e507",
    },
  },
  moonbeam: {
    network: {
      symbol: "MOONBEAM",
      chainId: 1284,
      chainName: "Moonbeam",
      chainIcon: "moonbeam",
      chainTargetId: 8,
      nativeCurrency: {
        name: "DEV",
        symbol: "DEV",
        decimals: 18,
      },
      rpcUrl: "https://rpc.api.moonbeam.network",
      blockExplorerUrl: "https://moonscan.io",
      refreshClaimTimestamp: 15000,
    },

    tokenAddresses: {
      WEWT: "0x5b1354c1Dc049A68344eF0CE4da61d8F616Ae7cb",
    },
  },
};
