import React from "react";

import { Icon } from "@stichting-allianceblock-foundation/components";
import classnames from "classnames";

import { DOTS, usePagination } from "./usePagination";

import "./pagination.scss";

const Pagination = (props: any) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (
    currentPage === 0 ||
    (paginationRange !== undefined && paginationRange.length < 2)
  ) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange
    ? paginationRange[paginationRange.length - 1]
    : 0;
  const lastIndexOfDots =
    paginationRange?.indexOf(DOTS) !== paginationRange?.lastIndexOf(DOTS)
      ? Number(paginationRange?.lastIndexOf(DOTS)) + 1
      : Number(paginationRange?.length) - 1;

  const lastIndexOfShort =
    Number(paginationRange?.length) - 1 >= 3
      ? 3
      : Number(paginationRange?.length) - 1;

  return (
    <ul
      className={classnames("pagination-container d-flex", {
        [className]: className,
      })}
    >
      <div className="pagination-buttons-container d-flex p-1">
        <li
          className={classnames(
            "pagination-arrow justify-content-center d-flex align-items-center text-align-center m-1",
            {
              disabled: currentPage === 1,
            }
          )}
          onClick={onPrevious}
        >
          <div className="d-flex align-items-center">
            <Icon size={16} color="brand-primary" name="chevron-left"></Icon>
          </div>
        </li>
        {paginationRange && currentPage > 3
          ? paginationRange
              .slice(lastIndexOfDots - 5, lastIndexOfDots)
              .map((pageNumber, index) => {
                if (pageNumber === DOTS) {
                  return (
                    <li
                      className="d-flex pagination-item dots align-items-center m-1"
                      key={`Pag-left-dots${pageNumber + index}`}
                    >
                      &#8230;
                    </li>
                  );
                }

                return (
                  <li
                    key={pageNumber}
                    className={classnames(
                      "pagination-item d-flex align-items-center m-1",
                      {
                        selected: pageNumber === currentPage,
                      }
                    )}
                    onClick={() => onPageChange(pageNumber)}
                  >
                    {pageNumber}
                  </li>
                );
              })
          : paginationRange?.slice(0, lastIndexOfShort).map((pageNumber) => {
              if (pageNumber === DOTS) {
                return (
                  <li
                    className="pagination-item dots"
                    key={`Pag-right-dots-${pageNumber}`}
                  >
                    &#8230;
                  </li>
                );
              }

              return (
                <li
                  key={pageNumber}
                  className={classnames(
                    "pagination-item d-flex align-items-center m-1",
                    {
                      selected: pageNumber === currentPage,
                    }
                  )}
                  onClick={() => onPageChange(pageNumber)}
                >
                  {pageNumber}
                </li>
              );
            })}
        <li
          className={classnames(
            "pagination-arrow d-flex justify-content-center align-items-center m-1",
            {
              disabled: currentPage === Number(lastPage) - 1,
            }
          )}
          onClick={onNext}
        >
          <div className="d-flex align-items-center">
            <Icon size={16} color="brand-primary" name="chevron-right"></Icon>
          </div>
        </li>
      </div>
    </ul>
  );
};

export default Pagination;
