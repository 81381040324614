import { useWeb3React } from "@web3-react/core";
import { useEffect } from "react";

import { ConnectorNames, connectorsByName } from "utils/web3React";

import { useGlobalContext } from "./useGlobalContext";

export function useInactiveListener(suppress: boolean = false) {
  const { isWalletConnected } = useGlobalContext();
  const { active, error, activate } = useWeb3React();
  const injectedConnector = connectorsByName[ConnectorNames.Injected];

  useEffect((): any => {
    const { ethereum } = window as any;

    if (
      !!ethereum &&
      !!ethereum.on &&
      !active &&
      !error &&
      !suppress &&
      isWalletConnected
    ) {
      const handleConnect = () => {
        console.log("Handling 'connect' event");
        activate(injectedConnector).catch((e) => console.log(e));
        ethereum?.removeAllListeners(["networkChanged"]);
      };
      const handleChainChanged = (chainId: string | number) => {
        console.log("Handling 'chainChanged' event with payload", chainId);
        activate(injectedConnector).catch((e) => console.log(e));
        ethereum?.removeAllListeners(["networkChanged"]);
      };
      const handleAccountsChanged = (accounts: string[]) => {
        console.log("Handling 'accountsChanged' event with payload", accounts);
        if (accounts.length > 0) {
          activate(injectedConnector).catch((e) => console.log(e));
          ethereum?.removeAllListeners(["networkChanged"]);
        }
      };

      ethereum.on("connect", handleConnect);
      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener("connect", handleConnect);
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
        }
      };
    }
  }, [active, error, suppress, activate, isWalletConnected]);
}
