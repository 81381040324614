import React from "react";

import "./Tooltip.scss";

const Tooltip = ({ text, className, children }: TooltipProps) => {
  return (
    <div data-tooltip={text} className={`tooltip ${className}`}>
      {children}
    </div>
  );
};

export default Tooltip;
