export const config = {
  eth: {
    network: {
      symbol: "ETH",
      chainId: 4,
      chainName: "Ethereum - Rinkeby",
      chainIcon: "ethereum",
      chainTargetId: 1,
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrl: `https://rinkeby.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
      blockExplorerUrl: "https://rinkeby.etherscan.io",
      refreshClaimTimestamp: 15000,
    },

    tokenAddresses: {
      WEWT: "0xFAa250fAaC55265D2325422c90d4C0B38FD5319a",
      WEJS: "0x387C9F91c0107E62644f11Ee9005F22BDECd6523",
    },
  },
  bsc: {
    network: {
      chainId: 97,
      symbol: "BSC",
      chainName: "Smart Chain - Testnet",
      chainIcon: "binance",
      chainTargetId: 2,
      nativeCurrency: {
        name: "BNB",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
      blockExplorerUrl: "https://testnet.bscscan.com",
      refreshClaimTimestamp: 15000,
    },
    tokenAddresses: {
      WEWT: "0x145A105066A112aBbF46A67fe2D5e09fA3f65938",
      WEJS: "0x145A105066A112aBbF46A67fe2D5e09fA3f65938",
    },
  },
  avalanche: {
    network: {
      symbol: "AVAX",
      chainId: 43113,
      chainName: "Avalanche - Fuji",
      chainIcon: "avalanche",
      chainTargetId: 3,
      nativeCurrency: {
        name: "AVAX",
        symbol: "AVAX",
        decimals: 18,
      },
      rpcUrl: "https://api.avax-test.network/ext/bc/C/rpc",
      blockExplorerUrl: "https://explorer.avax-test.network",
      refreshClaimTimestamp: 15000,
    },

    tokenAddresses: {
      WEWT: "0x0280D152682EC862ABC833BE83231f956f911116",
    },
  },
  polygon: {
    network: {
      symbol: "MATIC",
      chainId: 80001,
      chainName: "Polygon - Mumbai",
      chainIcon: "matic",
      chainTargetId: 4,
      nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
      },
      rpcUrl: "https://rpc-mumbai.maticvigil.com",
      blockExplorerUrl: "https://explorer-mumbai.maticvigil.com",
      refreshClaimTimestamp: 15000,
    },

    tokenAddresses: {
      WEWT: "0x5092De37a51C162066d06F6Dff1E9Fca46A7ad78",
    },
  },
  ewc: {
    network: {
      symbol: "EWC",
      chainId: 73799,
      chainName: "Energy Web Chain - Volta",
      chainIcon: "ewc",
      chainTargetId: 5,
      nativeCurrency: {
        name: "EWC",
        symbol: "EWC",
        decimals: 18,
      },
      rpcUrl: "https://volta-rpc.energyweb.org",
      blockExplorerUrl: "https://volta-explorer.energyweb.org",
      refreshClaimTimestamp: 15000,
    },

    tokenAddresses: {
      WEWT: "0xa8CCD60a1A3bF75d863036350BD8941b7a14B831",
    },
    EWTB: {
      ETH: "0x8a14f6384b66ced2ee42b42b5bdae443cf9baa86",
    },
  },
  arbitrum: {
    network: {
      symbol: "ARBITRUM",
      chainId: 421611,
      chainName: "Arbitrum - Rinkeby",
      chainIcon: "arbitrum",
      chainTargetId: 6,
      nativeCurrency: {
        name: "AETH",
        symbol: "AETH",
        decimals: 18,
      },
      rpcUrl: "https://rinkeby.arbitrum.io/rpc",
      blockExplorerUrl: "https://rinkeby-explorer.arbitrum.io",
      refreshClaimTimestamp: 15000,
    },

    tokenAddresses: {
      WEWT: "0x8f91265Caa8D969F0e6E9FEFc688E8DF27aF5BC8",
    },
  },
  optimistic: {
    network: {
      symbol: "OPTIMISTIC",
      chainId: 69,
      chainName: "Optimistic - Kovan",
      chainIcon: "optimistic",
      chainTargetId: 7,
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrl: "https://kovan.optimism.io/",
      blockExplorerUrl: "https://kovan-optimistic.etherscan.io",
      refreshClaimTimestamp: 15000,
    },

    tokenAddresses: {
      WEWT: "0xB7FbccE7764e7f2f9bC6d52CbE744Dd27c830E4a",
    },
  },
  moonbeam: {
    network: {
      symbol: "MOONBEAM",
      chainId: 1287,
      chainName: "Moonbase Alpha",
      chainIcon: "moonbeam",
      chainTargetId: 8,
      nativeCurrency: {
        name: "DEV",
        symbol: "DEV",
        decimals: 18,
      },
      rpcUrl: "https://rpc.api.moonbase.moonbeam.network",
      blockExplorerUrl: "https://moonbase.moonscan.io",
      refreshClaimTimestamp: 15000,
    },

    tokenAddresses: {
      WEWT: "0xD7F3F9C73E46a417cB104B3Bbc5e5d9317681581",
    },
  },
  aurora: {
    network: {
      symbol: "AURORA",
      chainId: 1313161555,
      chainName: "Aurora - Testnet",
      chainIcon: "aurora",
      chainTargetId: 9,
      nativeCurrency: {
        name: "AURORA",
        symbol: "AURORA",
        decimals: 18,
      },
      rpcUrl: "https://testnet.aurora.dev/",
      blockExplorerUrl: "https://explorer.testnet.aurora.dev",
      refreshClaimTimestamp: 15000,
    },

    tokenAddresses: {
      WEWT: "0xA1239211ef2cf179b54D5Ed7DAa76a6f4b94d4d1",
    },
  },
  fantom: {
    network: {
      symbol: "FANTOM",
      chainId: 4002,
      chainName: "Fantom - Testnet",
      chainIcon: "fantom",
      chainTargetId: 10,
      nativeCurrency: {
        name: "FTM",
        symbol: "FTM",
        decimals: 18,
      },
      rpcUrl: "https://rpc.testnet.fantom.network/",
      blockExplorerUrl: "https://testnet.ftmscan.com",
      refreshClaimTimestamp: 15000,
    },

    tokenAddresses: {
      WEWT: "0xfA6cd6e8fFD58b8BfF56677E0d490589CFa26eAd",
    },
  },
};
