import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "@stichting-allianceblock-foundation/components";

import ClaimButton from "../../ClaimButton/ClaimButton";

import "./ClaimListingWeb.scss";

const editIcon = (status: string | boolean) => {
  const { t } = useTranslation();
  return status === t("claimPage:buttonStatus.notClaimed") || !status ? (
    <Icon name="edit-claim" size={20} color="brand-primary" />
  ) : status === t("claimPage:buttonStatus.claiming") ? (
    <Icon name="staking-expiration-time" size={20} color="brand-primary" />
  ) : status === t("claimPage:buttonStatus.claimed") || status === true ? (
    <Icon name="edit-finalize" size={20} color="brand-primary" />
  ) : null;
};

const ClaimListingWeb = ({ entry }: { entry: FormattedTransfer }) => {
  const { t } = useTranslation();
  const [buttonStatus, setButtonStatus] = useState<string | undefined>();

  const trimmedNumber: string = entry.bridgedToken.amount.substring(0, 8);

  const tokenSymbolName: string = entry.bridgedToken.details.symbol;
  const isWrappedToken: string =
    tokenSymbolName.charAt(0) === "W"
      ? tokenSymbolName.replace(tokenSymbolName[0], "w")
      : tokenSymbolName;

  useEffect(() => {
    setButtonStatus(entry.status);
  }, []);
  return (
    <div className="entryTableWrapper d-flex justify-content-between align-items-center mt-3 mb-2 mx-4">
      <div className="icon-wrapper d-flex align-items-center justify-content-center">
        {editIcon(buttonStatus ? buttonStatus : "")}
      </div>
      <div className="info d-flex justify-content-between">
        <div className="from-icon-wrapper d-flex align-items-center">
          <Icon name={entry?.sourceNetwork?.chainIcon} size={20} />
          <p className="network-title text-main ml-3">
            {entry?.sourceNetwork?.chainName}
          </p>
        </div>

        <div className="main-target d-flex align-items-center">
          <Icon
            className="mr-1"
            name="arrow-right-long"
            color="brand-secondary"
            size={18}
          />
          <Icon name={entry?.targetNetwork?.chainIcon} size={20} />
          <p className="target-title text-main ml-3">
            {entry?.targetNetwork?.chainName}
          </p>
        </div>
      </div>

      <div className="amount-wrapper d-flex align-items-center justify-content-between ">
        <div
          className="token-amount-wrapper text-main ml-3 pl-1"
          title={entry.bridgedToken.amount}
          style={{ cursor: "pointer" }}
        >
          {trimmedNumber}
        </div>
        <div className="d-flex align-items-center">
          <Icon
            name={entry?.bridgedToken?.details?.icon}
            size={20}
            className="mx-3"
          />
          <p className="text-secondary">{isWrappedToken}</p>
        </div>
      </div>
      <div className="claim-status d-flex flex-row-reverse ml-3">
        <ClaimButton
          disabled={!entry.majorityReached}
          status={
            entry.status
              ? t("claimPage:buttonStatus.claimed")
              : t("claimPage:buttonStatus.notClaimed")
          }
          clicked={setButtonStatus}
          entry={entry}
        />
      </div>
    </div>
  );
};

export default ClaimListingWeb;
