import { useWeb3React } from "@web3-react/core";
import React, { useEffect } from "react";

import { Icon } from "@stichting-allianceblock-foundation/components";
import { Select } from "components/Select";
import { WalletButton } from "components/WalletButton";
import { NETWORK_CONFIG } from "configs/networks";
import { useGlobalContext } from "hooks/useGlobalContext";
import {
  addNetwork,
  changeCurrentNetwork,
  getMetamaskNetworkParams,
  MetamaskErrorCodes,
} from "utils/metamask";
import { getNetworkIndex } from "utils/network";

import "./Header.scss";

const Header = () => {
  const {
    networkOptions,
    currentNetwork,
    networkIndex,
    isWalletConnected,
    triedConnecting,
    networkSwitchScreen,
    setCurrentNetwork,
    setNetworkIndex,
    setIsSideMenuOpen,
    setSDK,
    setClaims,
    setNftClaims,
    setNetworkSwitchScreen,
  } = useGlobalContext();
  const { library, chainId, active } = useWeb3React();

  useEffect(() => {
    const setCurrentNetworkIndex = () => {
      if (isWalletConnected) {
        const chainId_ = chainId && active ? chainId : currentNetwork?.chainId;
        setNetworkIndex(getNetworkIndex(chainId_, networkOptions));
      } else {
        setNetworkIndex(
          getNetworkIndex(currentNetwork?.chainId, networkOptions)
        );
      }
    };

    setCurrentNetworkIndex();
  }, [chainId, triedConnecting, isWalletConnected, currentNetwork]);

  useEffect(() => {
    const deactivateNetworkSwitchScreen = () => {
      if (
        currentNetwork.chainId === chainId &&
        currentNetwork.chainId === networkSwitchScreen.network.chainId
      ) {
        setNetworkSwitchScreen({
          ...networkSwitchScreen,
          connecting: false,
        });
      }
    };

    deactivateNetworkSwitchScreen();
  }, [currentNetwork.chainId, chainId]);

  async function changeNetwork(option: Network) {
    setClaims([]);
    setNftClaims([]);
    try {
      setSDK(null);
      if (isWalletConnected) {
        setNetworkSwitchScreen({
          ...networkSwitchScreen,
          network: option,
          connecting: true,
        });
        await changeCurrentNetwork(option.chainId, library.provider);
      }
      setCurrentNetwork(option);
    } catch (err: any) {
      setSDK(null);
      if (err.code === MetamaskErrorCodes.NotNetworkFound) {
        try {
          setNetworkSwitchScreen({
            ...networkSwitchScreen,
            network: option,
            connecting: true,
          });
          await addNetwork(getMetamaskNetworkParams(option), library.provider);
        } catch (err) {
          console.error(
            "MetaMask - Add Network: An error ocurred when trying to add a new network.",
            err
          );
          setNetworkIndex(getNetworkIndex(chainId!, networkOptions));
          setNetworkSwitchScreen({
            ...networkSwitchScreen,
            connecting: false,
          });
        }
      } else if (err.code === MetamaskErrorCodes.UserRejectedRequest) {
        setNetworkIndex(getNetworkIndex(chainId!, networkOptions));
        setNetworkSwitchScreen({
          ...networkSwitchScreen,
          connecting: false,
        });
      }
    }
  }

  return (
    <div className="container-header">
      <div className="d-flex justify-content-end">
        <a className="link-primary d-lg-none mr-auto d-flex justify-content-center align-items-center cursor-pointer">
          <Icon
            name="menu"
            size={32}
            color="ui-main"
            onClick={() => setIsSideMenuOpen(true)}
          />
        </a>
        <Select
          className="network-select-component ml-4 mr-3"
          optionsList={Object.values(NETWORK_CONFIG)?.map(
            (item: any) => item?.network
          )}
          displayFields={{
            primary: "chainName",
            secondary: "",
            icon: "chainIcon",
          }}
          size="sm"
          selected={networkIndex}
          onSelectOptionChange={(option) => changeNetwork(option)}
        />
        <WalletButton />
      </div>
    </div>
  );
};

export default Header;
