import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { InfoTooltip } from "components/Tooltips";

const Title = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="d-flex align-items-center">
        <div className="text-medium text-bold text-main mb-3 mr-3">
          {t("transferPage:title")}
        </div>
        <InfoTooltip text={t("transferPage:titleTooltip")} size={20} />
      </div>
      <div className="text-main">
        <Trans
          i18nKey="transferPage:description"
          components={{ span: <span /> }}
        />
      </div>
    </>
  );
};

export default Title;
