import { config } from "./../configs/networks/testnet";

export const NetworkNameByChainId = {
  1: "Ethereum Mainnet",
  56: "Binance Smart Chain Mainnet",
  137: "Polygon",
  43114: "Avalanche C-Chain Mainnet",
  246: "Energy Web Chain Mainnet",
  42161: "Arbitrum One",
};

export const fullNetworkByChainId = {
  1: "Ethereum",
  56: "Binance Smart Chain",
  137: "Polygon",
  43114: "Avalanche C-Chain",
  246: "Energy Web Chain",
  4: "Ethereum Rinkeby",
  97: "BSC Testnet",
  43113: "Avalanche Testnet",
  80001: "Matic Mumbai",
  5: "Energy Volta",
  73799: "Energy Volta",
  421611: "Arbitrum Testnet",
  69: "Optimistic",
};

export const mappingNetworkName: { [name: string]: string } = {
  ETH: "Ethereum",
  BSC: "BSC Mainnet",
  AVAX: "AVAX",
  POLYGON: "POLYGON",
  MATIC: "MATIC",
  EWC: "EWC",
  ARBITRUM: "ARBITRUM",
  OPTIMISTIC: "OPTIMISTIC",
  SGB: "SONGBIRD",
  AURORA: "AURORA",
  AVALANCHE: "AVALANCHE",
};

export const mapingLogo: { [name: string]: string } = {
  ETH: "coin-eth",
  BSC: "coin-bnb",
  WALBT: "albt-white",
  WEWT: "coin-ewt",
  EWC: "coin-ewt",
};

export const mappingChainIdLogo: { [id: number]: string } = {
  1: "ethereum",
  2: "binance",
  3: "avalanche",
  4: "matic",
  5: "ewc",
  6: "arbitrum",
  7: "optimistic",
  8: "moonbeam",
  9: "aurora",
  10: "fantom",
};

export const mappingChainIdConfig: { [id: number]: string } = {
  1: "eth",
  2: "bsc",
  3: "avalanche",
  4: "polygon",
  5: "ewc",
  6: "arbitrum",
  7: "optimistic",
  8: "songbird",
  9: "aurora",
  10: "fantom",
};

export const NetworkSymbolByTargetChainId = {
  1: "ETH",
  2: "BNB",
  3: "AVAX",
  4: "MATIC",
  5: "VT",
  6: "ETH",
  7: "ETH",
};

export const Tokens = {
  UNI: "UNI",
  ETH: "ETH",
  AVAX: "AVAX",
  MATIC: "MATIC",
  EWT: "EWT",
};

export const Routes = {
  Home: "/",
};

export const FilterStatus = {
  all: "All Claims",
  notClaimed: "Not claimed",
  claimed: "Claimed",
  claiming: "Claiming...",
};

export const FilterNetwork = {
  all: "All Networks",
};

export const ChainIds = ((config: any) => {
  let currentNetworkItem, id, value;
  const chainIds = {} as any;

  Object.keys(config).forEach((key: any) => {
    currentNetworkItem = config[key];

    id = currentNetworkItem.network?.chainTargetId?.toString().toUpperCase();
    value = key.toUpperCase();
    chainIds[id] = value;
  });

  return chainIds;
})(config);
