import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "@stichting-allianceblock-foundation/components";
import { useDetectOutsideClick } from "hooks/useDetectClickOutside";
import { useGlobalContext } from "hooks/useGlobalContext";

import "./Select.scss";

const Select = ({
  optionsList,
  onSelectOptionChange,
  optionsListName,
  displayFields,
  selected,
  size,
  className,
  disabled,
  placeholder,
  customEmptyText,
  resetFlag,
}: SelectProps) => {
  const selectRef: MutableRefObject<null | any> = useRef(null);
  const [expanded, setExpanded] = useDetectOutsideClick(selectRef, false);
  const [selectedOption, setSelectedOption] = useState<Option>(
    optionsList[selected ? selected : 0]
  );
  const [placeholderFlag, setPlaceholderFlag] = useState<boolean>(true);

  const { currentNetwork } = useGlobalContext();

  const { t } = useTranslation();

  const handleChange = (option: Option) => {
    setExpanded(!expanded);
    setSelectedOption(option);
    if (onSelectOptionChange) {
      onSelectOptionChange(option);
    }
    if (placeholderFlag) {
      setPlaceholderFlag(false);
    }
  };

  useEffect(() => {
    if (placeholder?.reset) {
      setPlaceholderFlag(true);
    }
  }, [placeholder?.reset]);

  useEffect(() => {
    setPlaceholderFlag(placeholder?.active as boolean);
  }, [placeholder?.active]);

  useEffect(() => {
    if (optionsList.length === 1) {
      setSelectedOption(optionsList[0]);
    } else {
      setSelectedOption(optionsList[selected ? selected : 0]);
    }
  }, [selected, optionsList]);

  useEffect(() => {
    setPlaceholderFlag(!resetFlag);
  }, [resetFlag]);

  const getPlaceholder = () => {
    return (
      <span className="text-secondary text-bold d-flex align-items-center">
        {placeholder?.icon && (
          <Icon
            name={placeholder.icon}
            color="ui-secondary"
            size={placeholder?.iconSize ? placeholder?.iconSize : 18}
          />
        )}
        <span className="ml-3">{placeholder?.text}</span>
      </span>
    );
  };
  const getHeaderRow = () => {
    const headerContent =
      optionsList.length && selectedOption ? (
        <span className="text-main text-bold d-flex align-items-center">
          {selectedOption[displayFields.icon!] && (
            <Icon
              name={selectedOption[displayFields.icon!]}
              size={18}
              className="mr-2"
            />
          )}
          <span className="text-main mr-2">
            {selectedOption[displayFields.primary]}
          </span>
          {selectedOption[displayFields.secondary] && (
            <span className="text-secondary mr-2">
              {selectedOption[displayFields.secondary]}
            </span>
          )}
        </span>
      ) : (
        <span>{customEmptyText ?? t("select:noOptions")}</span>
      );
    return (
      <div
        className={
          expanded && !disabled ? "select-dropdown active" : "select-dropdown"
        }
      >
        {/* {isSearch ? <Icon name="nav-search" size={16}></Icon> : null} */}
        {placeholderFlag && placeholder?.text
          ? getPlaceholder()
          : headerContent}
        {!disabled && (
          <Icon
            className={
              expanded
                ? "arrow d-flex align-items-center"
                : "rotated arrow d-flex align-items-center"
            }
            name="chevron-up"
            size={16}
            color="ui-secondary"
          />
        )}
      </div>
    );
  };
  const getOptionList = () => {
    return (
      <ul
        className={`container-select-dropdown-list container-select-dropdown-list-${
          expanded ? "show" : "fade"
        } container-select-dropdown-list-${size ? size : "md"}`}
      >
        {optionsList.map((option, index) => {
          const { primary, secondary, icon } = displayFields;
          if (option.symbol !== currentNetwork.symbol) {
            return (
              <li
                className={
                  selectedOption &&
                  selectedOption[displayFields.primary] ===
                    option[displayFields.primary]
                    ? "selected"
                    : ""
                }
                key={index}
                onClick={() => handleChange(option)}
              >
                {option.chainName !== "All Networks" ? (
                  <Icon
                    name={option[icon!] ? option[icon!] : "check"}
                    size={18}
                    className="mr-3"
                  />
                ) : null}

                <span className="text-main mr-2">{option[primary]}</span>
                {option[secondary] && (
                  <span className="text-secondary">{option[secondary]}</span>
                )}
              </li>
            );
          }
        })}
      </ul>
    );
  };
  return (
    <div className={`component-select ${className}`} ref={selectRef}>
      {optionsListName && (
        <p className="text-secondary text-bold mb-3">{optionsListName}</p>
      )}
      <div
        className={`container-select-wrapper container-select-wrapper-${
          size ? size : "md"
        } ${!disabled ? "cursor-pointer" : "disabled"}`}
        onClick={() => setExpanded(!expanded)}
      >
        {getHeaderRow()}
      </div>
      {!disabled && getOptionList()}
    </div>
  );
};
export default Select;
