import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

import { JsonRpcSigner } from "@ethersproject/providers";
import {
  Button,
  Icon,
  LabelButton,
} from "@stichting-allianceblock-foundation/components";
import { Accordion } from "components/Accordion";
import { BuyALBT } from "components/Modals/BuyALBT";
import { FaucetMiningSuccess } from "components/Modals/FaucetMiningSuccess";
import { Modal } from "components/Modals/Modal";
import { InfoTooltip } from "components/Tooltips";
import { useBreakpoint } from "hooks/useBreakpoint";
import { useGlobalContext } from "hooks/useGlobalContext";
import { getTokenIcon, isProtocolAvailable, TxStatus } from "utils";
import { getAlbtContract } from "utils/contracts";

import "./SideMenu.scss";

enum SideMenuSection {
  Bridge,
  BridgeNft,
}

const SideMenu = ({ logo, title, subtitle }: SideMenuProps) => {
  const {
    isSideMenuOpen,
    setIsSideMenuOpen,
    bridgeStatuses,
    currentNetwork,
    sdk,
  } = useGlobalContext();
  const location = useLocation();
  const { t } = useTranslation();
  const { chainId, library } = useWeb3React();
  const { lessThan } = useBreakpoint();

  const accordionItemsRef = useRef(null);

  const RouteToSideMenuSection: { [route: string]: SideMenuSection } = {
    "/": SideMenuSection.Bridge,
    "/bridge": SideMenuSection.Bridge,
    "/bridge-nft": SideMenuSection.BridgeNft,
  };

  const [activeSection, setActiveSection] = useState<SideMenuSection>(() => {
    const pathname = location.pathname;
    const base = pathname.split("/")[1] ? `/${pathname.split("/")[1]}` : "/";
    return RouteToSideMenuSection[base];
  });
  const [signer, setSigner] = useState<JsonRpcSigner>();
  const [albtToken, setAlbtToken] = useState<Token>();
  const [faucetTx, setFaucetTx] = useState<string>("");
  const [mining, setMining] = useState<boolean>(false);
  const [miningSuccess, setMiningSuccess] = useState<boolean>(false);
  const [buyingAlbt, setBuyingAlbt] = useState<boolean>(false);

  useEffect(() => {
    const loadAlbtToken = async () => {
      if (sdk) {
        const token = await sdk.getALBTTokenLegacy();
        const icon = getTokenIcon(token);
        const albtToken_: Token = {
          address: token?.address,
          amount: "",
          details: {
            decimals: token?.decimals,
            symbol: token?.symbol,
            name: token?.name,
            icon,
          },
        };
        setAlbtToken(albtToken_);
      }
    };

    loadAlbtToken();
  }, [sdk]);

  const handleToggle = (e: React.MouseEvent, section: SideMenuSection) => {
    setActiveSection(section);

    const list = document.querySelectorAll(".accordion-item");

    for (const item in list) {
      if (typeof list[item] === "object") {
        if (list[item].classList.contains("active")) {
          list[item].classList.remove("active");
        }
      }
    }
    e.currentTarget.classList.add("active");

    if (lessThan("lg")) {
      setIsSideMenuOpen(false);
    }
  };

  const renderAlbtButton = () => {
    return (
      isProtocolAvailable(currentNetwork.chainId) && (
        <Button type="primary" className="" onClick={() => setBuyingAlbt(true)}>
          <Icon name="albt-white" size={20} />
          <span className="ml-2">{t("sideMenu:btn.buyALBT")}</span>
        </Button>
      )
    );
  };

  const handleAlbtFaucet = async () => {
    if (signer) {
      try {
        setMining(true);
        const albtContract = getAlbtContract(signer);
        const tx = await albtContract.faucet();
        const receipt = await tx.wait();

        setFaucetTx(receipt.transactionHash);

        if (receipt.status === TxStatus.READY) {
          setMiningSuccess(true);
          setMining(false);
          return;
        }
      } catch (error) {
        setMiningSuccess(false);
        setMining(false);
        console.error(error);
      }
    }
  };

  const renderFaucetButton = () => {
    return (
      <>
        {chainId === 4 &&
          (!mining ? (
            <Button
              type="primary"
              className="ml-3 flex-grow-1"
              onClick={handleAlbtFaucet}
            >
              <Icon name="albt-white" size={20} />
              <span className="ml-2">{t("sideMenu:btn.faucetALBT")}</span>
            </Button>
          ) : (
            <LabelButton
              className="ml-3 flex-grow-1 "
              type="primary"
              loading={true}
              loadingText={t("faucet:mining")}
              label={t("faucet:title")}
            />
          ))}
      </>
    );
  };

  const renderv2Button = () => {
    return (
      <>
        <Button
          type="primary"
          className="m-3 flex-grow-1"
          onClick={redirectToV2}
        >
          <Icon name="albt-white" size={20} />
          <span className="ml-2">{t("sideMenu:btn.bridgeV2")}</span>
        </Button>
        <InfoTooltip text={t("sideMenu:bridgeTooltip")} size={18} />
      </>
    );
  };

  const redirectToV2 = () => {
    //TODO: Add urls for V2 based on ENV build
  };

  //Bridge v3 works on top of the messaging protocol. To migrate your v2 tokens to v3, you have to bridge them back through Bridge v2, claim them on their native network, and bridge them again through Bridge v3.

  useEffect(() => {
    const loadSigner = async () => {
      if (library) {
        try {
          setSigner(library.getSigner());
        } catch (error) {
          console.log("error catched");
        }
      }
    };

    loadSigner();
  }, [library]);

  return (
    <div
      className={`sidemenu-container ${
        isSideMenuOpen ? "sidemenu-opened" : ""
      } pt-8 px-6 pb-3`}
    >
      <Modal
        className="p-0 pb-4 width-100 width-sm-80 width-md-50 width-lg-fixed-450"
        triggered={miningSuccess}
      >
        <FaucetMiningSuccess
          token={albtToken as Token}
          tx={faucetTx}
          onClose={() => setMiningSuccess(false)}
        />
      </Modal>
      <Modal className="p-0 pb-4 width-100 width-sm-80" triggered={buyingAlbt}>
        <BuyALBT onClose={() => setBuyingAlbt(false)} />
      </Modal>
      <div className="sidemenu-content" ref={accordionItemsRef}>
        {isSideMenuOpen && (
          <div className="d-flex justify-content-end mb-5">
            <Icon
              name="cross-big"
              size={32}
              color="ui-main"
              onClick={() => setIsSideMenuOpen(false)}
            />
          </div>
        )}
        <div className="d-flex flex-column">
          <NavLink
            className="text-decoration-none mb-5"
            to={{
              pathname: "/",
              state: { prevPath: location.pathname },
            }}
          >
            <div className="d-flex align-items-center ml-4">
              <img height={44} src={logo} alt={`${title} Logo`} />
              <div className="ml-4">
                <div className="text-secondary text-tiny">{title}</div>
                <div className="text-solid text-bold text-normal sidemenu-subtitle">
                  {subtitle}
                </div>
              </div>
            </div>
          </NavLink>
          <div className="mt-6">
            <div className="mb-4">
              <Accordion
                title={t("sideMenu:accordionBridge.title")}
                expanded={activeSection === SideMenuSection.Bridge}
              >
                <li>
                  <NavLink
                    className="accordion-item"
                    onClick={(e) => handleToggle(e, SideMenuSection.Bridge)}
                    to={{
                      pathname: "/",
                      state: { prevPath: location.pathname },
                    }}
                    exact
                  >
                    <span className="d-flex align-items-center">
                      <Icon
                        name="bridge-transfer"
                        size={20}
                        color="ui-secondary"
                      />
                      <span className="ml-3 text-bold">
                        {t("sideMenu:accordionBridge.transfer")}
                      </span>
                      <span className="quantity-number text-bold"></span>
                      <Icon
                        className="ml-3"
                        name="chevron-right"
                        size={16}
                        color="ui-secondary"
                      />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="accordion-item"
                    onClick={(e) => handleToggle(e, SideMenuSection.Bridge)}
                    to={{
                      pathname: "/bridge/claim",
                      state: { prevPath: location.pathname },
                    }}
                  >
                    <span className="d-flex align-items-center">
                      <Icon
                        name="bridge-claim"
                        size={20}
                        color="ui-secondary"
                      />
                      <span className="ml-3 text-bold">
                        {t("sideMenu:accordionBridge.claim")}
                      </span>
                      <span className="quantity-number text-bold">
                        {bridgeStatuses.bridge.activeClaims}
                      </span>
                      <Icon
                        className="ml-3"
                        name="chevron-right"
                        size={16}
                        color="ui-secondary"
                      />
                    </span>
                  </NavLink>
                </li>
              </Accordion>
            </div>
          </div>
        </div>
        <div className="sidemenu-buttons">
          <div className="text-center mr-4">
            {process.env.REACT_APP_NETWORK_CONFIG === "mainnet"
              ? renderAlbtButton()
              : renderFaucetButton()}
          </div>
          <div className="d-flex align-items-center justify-content-center mt-4">
            {/* Showed only on the FE */}
            <p className="text-border mx-3">v.2.0</p>
            <p className="text-border mx-3">
              Build:{process.env.REACT_APP_BUILD_VERSION}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
