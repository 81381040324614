import { useEffect, useState } from "react";

import { breakpoints } from "configs/constants";

const getWindowWidth = (): number => {
  const { innerWidth: width } = window;
  return width;
};

export const useBreakpoint = () => {
  const [width, setWidth] = useState<number>(getWindowWidth());

  const lessThan = (breakpoint: "sm" | "md" | "lg" | "xl"): boolean => {
    switch (breakpoint) {
      case "sm":
        return width < breakpoints.sm;
      case "md":
        return width < breakpoints.md;
      case "lg":
        return width < breakpoints.lg;
      case "xl":
        return width < breakpoints.xl;
    }
  };

  const greaterThan = (
    breakpoint: "xs" | "sm" | "md" | "lg" | "xl"
  ): boolean => {
    switch (breakpoint) {
      case "xs":
        return width > breakpoints.xs;
      case "sm":
        return width > breakpoints.sm;
      case "md":
        return width > breakpoints.md;
      case "lg":
        return width > breakpoints.lg;
      case "xl":
        return width > breakpoints.xl;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(getWindowWidth());
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    width,
    lessThan,
    greaterThan,
  };
};
