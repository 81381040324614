// eslint-disable-next-line simple-import-sort/imports
import React, { useEffect } from "react";

import { useGlobalContext } from "hooks/useGlobalContext";
import { useWeb3React } from "@web3-react/core";
import {
  getClaimCounters,
  getFormattedSdkClaims,
} from "../../../helpers/Claims";

import ClaimTitle from "components/Claim/ClaimTitle/ClaimTitle";
import ClaimFilter from "components/Claim/ClaimFilter/ClaimFilter";
import ClaimedCounter from "components/Claim/ClaimedCounter/ClaimedCounter";
import ClaimWindowContainer from "components/Claim/ClaimWindow/ClaimListing/ClaimWindowContainer/ClaimWindowContainer";

import { addAsset, getMetamaskProvider, isMetamask } from "utils/metamask";
import { Modal } from "components/Modals/Modal";
import { ClaimSuccess } from "components/Modals/ClaimSuccess";
import "./ClaimPage.scss";
const ClaimPage = () => {
  const {
    sdk,
    currentNetwork,
    bridgeStatuses,
    claimModal,
    setClaims,
    setBridgeStatuses,
    setClaimModal,
  } = useGlobalContext();
  const { account } = useWeb3React();

  useEffect(() => {
    const updateClaims = async () => {
      if (sdk) {
        try {
          const formattedClaims: Claim[] = await getFormattedSdkClaims(
            sdk,
            account as string,
            currentNetwork.chainTargetId as number
          );
          setClaims(formattedClaims);
          getToBeClaimedCounter(formattedClaims);
        } catch (error: any) {
          console.error(error);
        }
      }
    };

    const interval = setInterval(
      updateClaims,
      currentNetwork.refreshClaimTimestamp
    );
    if (sdk) {
      updateClaims();
    }
    return () => {
      clearInterval(interval);
    };
  }, [account, sdk]);

  function getToBeClaimedCounter(claims: Claim[]) {
    const countersObject = getClaimCounters(claims);
    setBridgeStatuses({
      ...bridgeStatuses,
      bridge: {
        ...bridgeStatuses.bridge,
        activeClaims: countersObject.counterClaimed,
        inActiveClaims: countersObject.counterNotClaimed,
      },
    });
  }
  const addTokenToMetamask = async (
    type: MetamaskAssetParamsType,
    options: MetamaskAssetParamsOptions
  ) => {
    try {
      const provider = getMetamaskProvider();
      await addAsset(type, options, provider);
    } catch (err) {
      console.error("An error occurred while trying to add the token.");
    }
  };

  return (
    <div className="claim-page-container">
      <Modal triggered={claimModal.status} className="p-0 pb-4">
        <ClaimSuccess
          claim={claimModal.claimData}
          addTokenToMetamask={addTokenToMetamask}
          isMetamask={isMetamask()}
          onClose={() => {
            setClaimModal({ status: false, claimData: {} } as ClaimModal);
          }}
        />
      </Modal>
      <div className="data-wrapper mt-7 mb-5 my-md-7">
        <ClaimTitle />
        <div className="claimed-option-wrapper my-4 pb-4 d-flex align-items-sm-end justify-content-between">
          <ClaimFilter />
          <div className="line mx-5 mb-6 mt-4 d-sm-none"></div>
          <ClaimedCounter />
          <div className="line mx-5 mt-6 d-sm-none"></div>
        </div>
        <ClaimWindowContainer />
      </div>
    </div>
  );
};

export default ClaimPage;
