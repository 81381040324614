import React from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Icon,
  LabelButton,
  StepButtons,
} from "@stichting-allianceblock-foundation/components";
import { BlockExplorerBadge } from "components/BlockExplorerBadge";
import { Modal, ModalButton } from "components/Modals/Modal";
import { TransferConfirmation } from "components/Modals/TransferConfirmation";
import { useGlobalContext } from "hooks/useGlobalContext";

interface StepBridgeButtonsProps {
  notEnoughBalanceForServiceFee: boolean;
  isTokenEWTB: boolean;
  tokenIsNotWrapped: boolean;
  isServiceTokenApproved: boolean;
  isBridgeTokenApproved: boolean;
  bridgeSteps: BridgeSteps;
  updateBridgeSteps: (steps: BridgeSteps) => void;
  updateActiveStep: (activeStep: 0 | 1) => void;
  approveServiceFeeToken: () => void;
  approveBridgeToken: () => void;
  sendBridgeToken: () => void;
  clearBridgeTransaction: () => void;
}

const StepBridgeButtons = ({
  notEnoughBalanceForServiceFee,
  isTokenEWTB,
  tokenIsNotWrapped,
  isServiceTokenApproved,
  isBridgeTokenApproved,
  bridgeSteps,
  updateBridgeSteps,
  updateActiveStep,
  approveServiceFeeToken,
  approveBridgeToken,
  sendBridgeToken,
  clearBridgeTransaction,
}: StepBridgeButtonsProps) => {
  const { bridgeTransaction } = useGlobalContext();
  const { t } = useTranslation();

  const renderStepperButton = () => {
    if (bridgeSteps.next) {
      return (
        <Button
          type="primary"
          className="mb-4 mb-md-0 order-first order-md-last"
          disabled={
            !bridgeTransaction?.network?.target?.chainId ||
            !bridgeTransaction?.token?.address ||
            ["0", "0.0"]?.includes(bridgeTransaction?.token?.amount) ||
            !bridgeTransaction?.recipient ||
            notEnoughBalanceForServiceFee ||
            isTokenEWTB ||
            tokenIsNotWrapped
          }
          onClick={() => {
            updateBridgeSteps({
              ...bridgeSteps,
              next: false,
              approveServiceFeeToken: !isServiceTokenApproved,
              approveBridgeToken:
                !isBridgeTokenApproved && isServiceTokenApproved,
              transfer: isBridgeTokenApproved && isServiceTokenApproved,
            });
            updateActiveStep(1);
          }}
        >
          <span className="mr-2">{t("transferPage:nextButton")}</span>
          <Icon name="arrow-right-long" size={18} color="ui-border" />
        </Button>
      );
    } else if (bridgeSteps.processing) {
      return (
        <LabelButton
          className="mb-4 mb-md-0 order-first order-md-last"
          type="primary"
          loading={true}
          loadingText={t("transferPage:stepReview.processingText")}
          label={
            bridgeSteps?.blockExplorer?.hash ? (
              <BlockExplorerBadge
                title={bridgeSteps?.blockExplorer?.name}
                hash={bridgeSteps?.blockExplorer?.hash as string}
                blockExplorer={
                  bridgeSteps?.blockExplorer?.blockExplorerUrl as string
                }
                type="tx"
              />
            ) : (
              <span className="text-center">
                {t("transferPage:stepReview.waitingForConfirmationText")}
              </span>
            )
          }
        />
      );
    } else if (
      bridgeSteps.approveServiceFeeToken ||
      bridgeSteps.approveBridgeToken
    ) {
      return (
        <Button
          type="primary"
          className="mb-4 mb-md-0 order-first order-md-last"
          onClick={
            bridgeSteps.approveServiceFeeToken
              ? approveServiceFeeToken
              : approveBridgeToken
          }
        >
          <span className="mr-2">
            {t("transferPage:approveButton", {
              tokenSymbol: bridgeSteps.approveServiceFeeToken
                ? bridgeTransaction?.feeToken?.details?.symbol
                : bridgeTransaction?.token?.details?.symbol,
            })}
          </span>
          <Icon name="arrow-right-long" size={18} color="ui-border" />
        </Button>
      );
    } else if (bridgeSteps.transfer) {
      return (
        <Modal
          button={
            <ModalButton
              type="primary"
              className="mb-4 mb-md-0 order-first order-md-last"
            >
              <span className="mr-2">{t("transferPage:transferButton")}</span>
              <Icon name="arrow-right-long" size={18} color="ui-border" />
            </ModalButton>
          }
          className="p-0 pb-4"
        >
          <TransferConfirmation
            title={t("transferPage:transferConfirmation.title")}
            subtitle={t("transferPage:transferConfirmation.subtitle")}
            confirmationTerms={t(
              "transferPage:transferConfirmation.confirmationTerms"
            )}
            onConfirmation={sendBridgeToken}
          />
        </Modal>
      );
    }
  };

  const renderResetButton = () => {
    if (bridgeSteps.next) {
      return (
        <Button
          type="alarm"
          className="mb-4 mb-md-0 mr-md-2 order-last order-md-first"
          onClick={clearBridgeTransaction}
        >
          <Icon name="renew" size={18} color="ui-border" />
          <span className="ml-2">{t("transferPage:clearButton")}</span>
        </Button>
      );
    } else {
      return (
        <Button
          type="alarm"
          className={`mb-4 mb-md-0 mr-md-2 order-last order-md-first ${
            bridgeSteps.processing ? "alarm-disabled" : ""
          }`}
          onClick={() => {
            if (!bridgeSteps.processing) {
              updateBridgeSteps({
                ...bridgeSteps,
                next: true,
                approveServiceFeeToken: false,
                approveBridgeToken: false,
                transfer: false,
              });
              updateActiveStep(0);
            }
          }}
        >
          <Icon name="edit-clear" size={18} color="ui-border" />
          <span className="ml-2">{t("transferPage:goBackButton")}</span>
        </Button>
      );
    }
  };

  return (
    <div className="mt-8">
      <StepButtons>
        <div className="d-flex flex-column flex-md-row justify-content-md-between">
          {renderResetButton()}
          {renderStepperButton()}
        </div>
      </StepButtons>
    </div>
  );
};

export default StepBridgeButtons;
